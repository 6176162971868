<template>
  <div class="join_box">
    <!-- 轮播图 -->
    <div class="carousel_box">
      <img class="carousel_img" src="@/assets/images/join-img1.png" alt="">
      <div class="carousel_text">
        <div>
          <span class="carousel_text1">
            {{ $t('MoreCommitment') }}
            <i class="iconfont icon-xingzhuangjiehe"/>
          </span>
          <span class="carousel_text1">
            {{ $t('MoreAction') }}
            <i class="iconfont icon-xingzhuangjiehe"/>
          </span>
          <span class="carousel_text1">
            {{ $t('FormHealthy') }}
            <i class="iconfont icon-xingzhuangjiehe"/>
          </span>
        </div>
        <div class="carousel_text2">{{ $t('NodcareWelcome') }}</div>
      </div>
    </div>

    <div 
      v-if="false"
      class="join-item">
      <!-- 地区筛选 -->
      <div class="join-list">
        <span>
          <!-- 工作地点： -->
          {{ $t('WorkingLocation') }}：
        </span>
        <div 
          v-if="areaList.length"
          class="join-list-inline">
          <div
            class="join-list-item"
            :class="{'join-list-item-change': item.isCheck}"
            v-for="(item, index) in areaList"
            :key="index"
            @click="joinChangeEvent(index, 'area')">
            {{ item.name }}
          </div>
        </div>
        <div 
          v-else 
          class="join-list-inline">
          <!-- 暂无数据 -->
          {{ $t('noData') }}
        </div>

      </div>
      <!-- 类目筛选 -->
      <div>
        <span>
            <!-- 职位类别： -->
            {{ $t('JobCategory') }}：
          </span>
        <div 
          v-if="areaList.length"
          class="join-list-inline">
          <div
            class="join-list-item"
            :class="{'join-list-item-change': item.isCheck}" 
            v-for="(item, index) in typeList"
            @click="joinChangeEvent(index, 'type')"
            :key="index">
            {{ item.name }}
          </div>
         </div>
        <div 
          v-else 
          class="join-list-inline">
          <!-- 暂无数据 -->
          {{ $t('noData') }}
        </div> 
      </div>
    </div>

    <!-- 展示列表 -->
    <div 
      v-if="workShowList.length"
      class="join-item">
      <el-table
        :data="workShowList"
        style="width: 100%"
        :default-sort = "{prop: 'date', order: 'descending'}"
        >
        <!-- 职位名称 -->
        <el-table-column
          prop="name"
          :label="$t('JobTitle')"
          width="180">
        </el-table-column>
        <!-- 职位类别 -->
        <el-table-column
          prop="type"
          :label="$t('JobCategory')">
        </el-table-column>
        <!-- 更新时间 -->
        <el-table-column
          prop="time"
          :label="$t('UpdateTime')"
          width="180">
        </el-table-column>
        <!-- 工作地点 -->
        <el-table-column
          prop="address"
          :label="$t('WorkingPlace')"
          width="140">
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination-class"
        background
        layout="prev, pager, next"
        hide-on-single-page
        :total="workTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 关于诺多康页面
export default {
  name: 'first-page',
  components: {

  },

  data() {
    return {
      itemList: [{
        url: require('@/assets/images/join-img1.png')
      }],
      areaList: [],
      typeList: [],
      tableData: [],
      language: 'zh', // 中文展示

      workList: [],
      workShowList: [], // 展示list
      workTotal: 1
    }
  },

  mounted () {
    // 添加对 sessionStorage 的监听
    this.workList = JSON.parse(sessionStorage.getItem('workList') || '[]')

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    });
  },

  methods: {
    init () {
      let language = sessionStorage.getItem("language")
      this.workShowList = []
      if (language === 'zh') {
        this.workList.forEach((item) => {
          this.workShowList.push({
            name: item.workName,
            type: item.workType,
            time: this.parseTime(item.updateTime),
            address: item.workCity
          })
        })
      } else {
        this.workList.forEach((item) => {
          this.workShowList.push({
            name: item.workNameEng,
            type: item.workTypeEng,
            time: this.parseTime(item.updateTime),
            address: item.workCityEng
          })
        })
      }
    },

    // 时间戳转为正常时间的公共方法，当然你也可以加上小时、分、秒
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },

    joinChangeEvent (seleIndex, type) {
      if (type === 'area') {
        this.areaList.forEach((item, index) => {
          item.isCheck = false
          if (seleIndex === index) {
            item.isCheck = true
          }
        })
      } else {
        this.typeList.forEach((item, index) => {
          item.isCheck = false
          if (seleIndex === index) {
            item.isCheck = true
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.join_box{
  margin-bottom: 100px;
}
.carousel_box{
  position: relative;
}
.carousel_img{
  width: 100%;
  background: #368fff;
}

.pagination-class{
  margin-top: 20px;
  text-align: right;
  padding: 0 !important;

  ::v-deep .el-pager li:not(.disabled).active{
    background: rgba(222,25,0,0.1000) !important;
    color: #DE1900 !important;
    border: 1px solid #DE1900 !important;
  }

  ::v-deep .btn-next, 
  ::v-deep .btn-prev, 
  ::v-deep .el-pager li {
    width: 32px;
    font-weight: initial !important;
    height: 32px;
    border: 1px solid #fff;
    background: #fff !important;
  }
  ::v-deep .el-pager li:hover{
    color: #DE1900 !important;
  }
}

::v-deep .el-table .descending .sort-caret.descending{
  border-top-color: #DE1900
}

::v-deep .el-table .ascending .sort-caret.ascending{
  border-bottom-color: #DE1900
}

::v-deep .el-table th.el-table__cell{
  background: #F4F6F9 !important;
}

::v-deep .el-table, .el-table__expanded-cell{
  background: transparent !important;
}

::v-deep .el-table::before{
  display: none;
}

::v-deep .el-table tr{
  background: transparent !important;
}

.join-list{
  &::after{
    width: 100%;
    height: 0;
    border: 1px dashed #E9E9E9;
    display: block;
    content: '';
    margin: 20px 0;
  }
}
.join-item{
  margin: 40px 124px 0;
  .join-list-item{
    display: inline-block;
    margin-right: 8px;
    padding: 0 12px;
    line-height: 22px;
    cursor: pointer;
    border-radius: 4px;
  }
  .join-list-item-change{
    background: #DE1900;
    color: #fff;
  }
}
.join-list-inline {
  display: inline-block;
}

.carousel_text{
  position: absolute;
  z-index: 1;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.carousel_text1 {
  font-weight: bold;
  padding: 0 44px;
  font-size: 40px;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
  .icon-xingzhuangjiehe{
    color: #DE1900;
    font-size: 20px;
    text-shadow: none;
    position: relative;
    top: -32px;
  }
}
.carousel_text2 {
  font-size: 32px;
  margin-top: 32px;
  text-align: center;
}

.dizhi-class{
  margin: 0 auto;
  width: 1000px;
  display: block;
}
.join_box{
  margin-bottom: 0 !important;
}
</style>
