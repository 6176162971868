<template>
  <div/>
</template>
<script>
// 首页页面
export default {
  name: 'CommonPage',
  components: {
  },
  data() {
    return {

    };
  },

  created () {

  },

  mounted () {
    // let that = this
    // 新闻列表
    this.$_http.post('/system/news/list', {})
    .then(response => {
      // 存入sess缓存
      sessionStorage.setItem('newsList', JSON.stringify(response.data.rows))
      // this.setSessionItem("newsList", JSON.stringify(response.data.rows))
    })

    // 获取案例列表
    this.$_http.post('/system/case/web/list', {})
    .then(response => {
      console.log(response);
      // 存入sess缓存
      sessionStorage.setItem('caseList', JSON.stringify(response.data))
      // this.setSessionItem("caseList", JSON.stringify(response.data.rows))
    })

    // 岗位列表
    this.$_http.post('/system/work/list', {})
    .then(response => {
      // 存入sess缓存
      sessionStorage.setItem('workList', JSON.stringify(response.data.rows))
      // this.setSessionItem("workList", JSON.stringify(response.data.rows))
    })

    // 获取产品列表
    this.$_http.post('/system/product/web/list', {})
    .then(response => {
      // 存入sess缓存
      sessionStorage.setItem('productList', JSON.stringify(response.data))
      this.$router.push({
        name: 'HomePage'
      })
      this.setSessionItem("time", String(new Date()))
    })
  },

  methods: {
    // 跳转页面
    skipPage (data, type, index) {
      console.log(data)
      // 修改选中状态
      this.tabList.forEach(itemData => {
        // 选中状态修改
        this.$set(itemData, 'isActive', false)
        this.$set(itemData, 'isChildActive', false)
        if (itemData.myName === data.myName) {
          this.$set(itemData, 'isActive', true)
          // 展开子页面
          if (type === 'open') {
            this.$set(itemData, 'isClose', false)
          }
        }
        itemData.child.forEach(itemData1 => {
          this.$set(itemData1, 'isActive', false)
          if (itemData1.myName === data.myName) {
            this.$set(itemData1, 'isActive', true)
            // 子集选中，父级状态
            this.$set(itemData, 'isChildActive', true)
          }
        })
      })

      // 跳转参数
      let query = {
        title: data.myName,
        index: index,
      }

      // 判断是否有jourId参数
      if (data.jourId) {
        query.jourId = data.jourId
      }

      // 判断是否是app
      if (sessionStorage.getItem('appChange') === 'true') {
        this.openLeft = false
      }

      // 跳转页面
      this.$router.push({
        name: data.path,
        query: query
      })
    },

    // app端展示目录 
    changeMuluEvent () {
      this.appMuluOpen = !this.appMuluOpen
      this.openLeft  = !this.openLeft
    },

    // 跳转首页
    skipHomePage () {
      // 判断是否是app
      if (sessionStorage.getItem('appChange') === 'true') {
        this.openLeft = false
      }

      this.$router.push({
        name: 'HomePage'
      })
    },

    // 跳转页面
    skipOtherPage (path, name) {
      this.$router.push({
        name: path,
        query: {
          title: name
        }
      })
    },
  
    // 展开收起事件
    openOrCloseEvent(index) {
      this.tabList.forEach((itemData, indexData) => {
        if (index === indexData) {
          this.$set(itemData, 'isClose', !itemData.isClose)
        }
      })
    },

    handleClose(key, keyPath) { 
      console.log(key, keyPath);
    },

    // input回车事件
    inputChange (e) {
      console.log('inputChange', e)
    },

    // 状态修改事件
    typeChange (data) {
      // 修改选中状态
      this.tabList.forEach(itemData => {
        // 选中状态修改
        this.$set(itemData, 'isActive', false)
        this.$set(itemData, 'isChildActive', false)
        if (itemData.path === data.name) {
          this.$set(itemData, 'isActive', true)
        }

        // // 判断是案例详情
        if (data.name === 'CaseDetail' && itemData.path === 'CaseList') {
          this.$set(itemData, 'isActive', true)
        }

        itemData.child.forEach(itemData1 => {
          this.$set(itemData1, 'isActive', false)
          if (itemData1.path === data.name && itemData1.myName === data.query.title) {
            this.$set(itemData1, 'isActive', true)
            // 子集选中，父级状态
            this.$set(itemData, 'isChildActive', true)
          }
        })
      })
    },

    // 切换语言事件
    changeLangEvent () {
      console.log(this.$i18n.locale)
      if (sessionStorage.getItem('language') === 'zh') {
        sessionStorage.setItem('language', 'en')
        this.$i18n.locale = 'en'
        this.language = 'en'
      } else {
        sessionStorage.setItem('language', 'zh')
        this.$i18n.locale = 'zh'
        this.language = 'zh'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-backtop, .el-calendar-table td.is-today{
  color: #DE1900;
}

.index-box{
  width: 100%;
  min-width: 1344px;
  height: 100%;
  overflow-x: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  .index-nav{
    width: calc(100% - 48px);
    height: 64px;
    background: #DE1900;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    .logo-box{
      width: 128px;
      margin: 18px 0;
      cursor: pointer;
      img{
        width: 100%;
      }
    }
    .index-nav-right {
      margin: 16px 0;
      display: flex;
      .icon-box{
        padding-left: 24px;
        height: 24px;
        margin: 4px 0 4px 24px;
        border-left: 1px solid rgba(255,255,255,.5);

        .iconfont {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          cursor: pointer;
          width: 28px;
          display: inline-block;
        }
      }
    }
    .index-input {
      width: 220px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      background: rgba(255,255,255,.3);
      color: rgba(255,255,255,.9);
      padding: 0 12px 0 0;
      .iconfont {
        color: #fff;
        line-height: 32px;
        cursor: pointer;
      }
    }
    ::v-deep .el-input__inner{
      height: 32px;
      border-radius: 16px;
      border: none;
      background: transparent;
      color: rgba(255,255,255,.9);
    }
  }
  .index-left{
    width: 210px;
    background: #fff;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    height: calc(100vh - 64px);
    z-index: 1;
    transition-duration: .2s;
    .index-l-nav{
      height: 60px;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      line-height: 60px;
      background: #F6F7F9;
    }
    .index-l-nav-bth{
      cursor: pointer;
      &:hover {
        color: #DE1900;
      }
    }
  }
  .index-left-change {
    width: 68px;
    .menu-box{
      display: none;
    }
    .index-l-nav-title{
      display: none;
    }
  }
  .index-right {
    // width: calc(100% - 210px);
    flex: 1;
    background: #FAFAFC;
    height: calc(100vh - 64px);
    overflow-y: auto;
  }
  .index-content-box{
    display: flex;
    font-size: 14px;
    color: #666;
  }
}

.menu-box {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.menu-title{
  display: flex;
  justify-content: space-between;
  span{
    display: inline-block;
  }
  i {
    line-height: 40px;
    width: 24px;
    text-align: center;
    transform-origin: center center;
    transition-duration: .3s;
  }
}

.menu-item-content{
  padding: 0 20px 0 24px;
  font-size: 14px;
  color: #666;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(45deg, #DE1900 0%, #EC7D6F 100%);
    color: #fff;
  }
}
.menu-child-name{
  padding-left: 40px;
  background: #F4F6F9;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition-duration: .1s;
}
.isActive{
  color: #DE1900;
  background: #FCECEA;
}
.isActive-child{
  color: #DE1900;
}
.isChildActive{
  background: #FCECEA;
}
.isClose{
  height: 0;
}
.isRotate{
  transform: rotate(180deg);
}

.index-r-bottom{
  background: #F4F6F9;
  padding: 28px 58px 20px;
  height: 152px;
}

.index-r-hear{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(139,144,160,0.2000);
  .index-r-hear1{
    flex: 1;
    display: flex;
    .index-r-hear1-item {
      margin-right: 80px;
      div {
        margin-bottom: 16px;
        font-size: 14px;
        cursor: pointer;
        &:hover{ 
          color: #DE1900;
        }
      }
    }
  }
  .index-r-hear2{
    width: 218px;
    div {
      margin-bottom: 16px;
      font-size: 14px;
      cursor: pointer;
      &:hover{ 
        color: #DE1900;
      }
    }
  }
  .index-r-hear3{
    width: 154px;
    div {
      width: 88px;
      height: 88px;
      background: #fff;
      border-radius: 4px;
    }
    img{
      width: 72px;
      height: 72px;
      margin: 8px;
      background: #368fff;
    }
  }
}
.index-r-hear-change{
  border-bottom: none;
  margin-bottom: 0;
}
.index-r-ys{
  width: 100%;
  padding: 16px 0;
  text-align: center;
  background: #DE1900;
  color: #fff;
  font-size: 12px;
  span{
    display: inline-block;
    &::after{
      display: inline-block;
      width: 1px;
      height: 16px;
      background: rgba(255, 255, 255, .5);
      content: '';
      margin: 0 8px;
      position: relative;
      top: 4px;
    }
    &:last-child::after{
      display: none;
    }
  }
}
.icon-huidaodingbu{
  font-size: 22px;
  position: relative;
  top: 2px;
}

.app-mulu{
  display: none;
  i{
    font-size: 24px;
    color: #fff;
  }
}
</style>
