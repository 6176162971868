<template>
  <div 
    id="indexBox"
    :class="{'indexBoxChange1': changeType === 'change1'}"
    class="index-box">
    <!-- 头部状态栏 -->
    <div class="index-nav">
      <!-- logo部分 -->
      <div 
        @click="skipHomePage"
        class="logo-box">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <!-- 右侧部分 -->
      <div class="index-nav-right">
        <div class="app-mulu">
          <i
            v-if="appMuluOpen"
            @click="changeMuluEvent"
            class="icon-app_mulushouqi iconfont"/>
          <i
            v-else
            @click="changeMuluEvent"
            class="icon-app_muluzhankai iconfont"/>
        </div>
        <div 
          v-if="false"
          class="index-input">
          <el-input 
            v-model="myInput"
            @change="inputChange"/>
            <i 
              @click="inputChange"
              class="iconfont icon-chazhao"/>
        </div>
        <div class="icon-box">
          <i 
            v-if="language === 'en'"
            @click="changeLangEvent"
            class="icon-yingwen iconfont"/>
          <i 
            v-else
            @click="changeLangEvent"
            class="icon-zhongwen1 iconfont"/>
        </div>
      </div>
    </div>
    <div class="index-content-box">
      <!-- 左侧导航部分 -->
      <div
        v-if="openLeft"
        class="index-left"
        :class="{'index-left-change': !isOpenType}">
        <div class="index-l-nav">
          <div class="index-l-nav-title">{{ $t('catalogue') }}</div>
          <div 
            class="index-l-nav-bth" 
            @click="isOpenType = !isOpenType">
            <i 
              class="iconfont"
              :class="{
                'icon-mulushouqi': isOpenType,
                'icon-muluzhankai': !isOpenType
              }"/>
          </div>
        </div>
        <div class="menu-box">
          <div
            class="menu-item"
            v-for="(item, index) in tabList"
            :key="index">
            <div
              v-if="item.child && item.child.length"
              class="menu-child"
              :index=index>
              <div
                class="menu-title menu-item-content"
                :class="{
                  'isActive': item.isActive,
                  'isChildActive': item.isChildActive
                }"
                @click="skipPage(item, 'open', index)">
                <span>{{  tabNameList[item.myName] }}</span>
                <i 
                  class="el-icon-arrow-down"
                  :class="{'isRotate': item.isClose}"
                  @click.stop="openOrCloseEvent(index)"/>
                </div>
              <div
                v-for="(item1, index1) in item.child"
                :key="index * 10 + index1"
                :title="item1.myName"
                class="menu-child-name menu-item-content"
                :class="{
                  'isActive-child': item1.isActive,
                  'isClose': item.isClose
                }"
                @click.stop="skipPage(item1, skipPage, index1)">
                {{ tabNameList[item1.myName] || item1.myName }}
              </div>
            </div>
            <div
              v-else
              class="menu-first menu-item-content"
              :title="item.myName"
              :class="{'isActive': item.isActive}"
              @click="skipPage(item, '', index)">
              {{ tabNameList[item.myName] }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧内容部分 -->
      <div 
        ref="indexRight"
        class="index-right">
        <!-- 主体内容部分切换 -->
        <div class="index-r-top">
          <router-view /> 
        </div>
        <!-- 底部菜单栏部分 web端-->
        <div class="index-r-bottom index-r-bottom-web">
          <div class="index-r-hear index-r-hear-flex">
            <div class="index-r-hear1">
              <!-- 快速链接 -->
              {{ $t('QuickLinks') }}
            </div>
            <div class="index-r-hear2 index-r-phone">
              <!-- 客服电话 -->
              {{ $t('CustomerService') }}
            </div>
            <div class="index-r-hear3">
              <!-- 官方微信 -->
              {{ $t('TheOfficialWeChat') }}
            </div>
          </div>
          <div class="index-r-hear index-r-hear-change">
            <div class="index-r-hear1">
              <div class="index-r-hear1-item">
                <div @click="skipOtherPage('CompanyIntro', '企业简介')">
                  <!-- 企业简介 -->
                  {{ $t('CompanyIntro') }}
                </div>
                <div @click="skipOtherPage('CompanyCulture', '企业文化')">
                  <!-- 企业文化 -->
                  {{ $t('CompanyCulture') }}
                </div>
                <div @click="skipOtherPage('ProductList', '产品&解决方案')">
                  <!-- 产品&解决方案 -->
                  {{ $t('ProductList') }}
                </div>
                
              </div>
              <!-- <div class="index-r-hear1-item">
                
              </div> -->
              <div class="index-r-hear1-item">
                <div
                  v-show="newsList.length"
                  @click="skipOtherPage('JournalismList', '公司新闻')">
                  <!-- 公司新闻 -->
                  {{ $t('JournalismList') }}
                </div>
                <div @click="skipOtherPage('JoinUs', '加入我们')">
                  <!-- 加入我们 -->
                  {{ $t('JoinUs') }}
                </div>
              </div>
            </div>
            <div class="index-r-hear2">
              <div>+86 0551-62990333</div>
              <div>+86 0551-62990555</div>
            </div>
            <div class="index-r-hear3 index-r-ewm">
              <div>
                <img src="@/assets/images/ewm.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 隐私条例 -->
        <div class="index-r-ys">
          <span>
            Copyright@2011-2022 
            <!-- 安徽诺康医疗科技有限公司 -->
            
            {{ $t('Technology') }}
          </span> 
          <span>这里是京ICP备案号</span>
          <span>NDC@nodcareplus.com</span>
        </div>
      </div>
    </div>

    <el-backtop target=".index-right" :bottom="100">
      <i class="icon-huidaodingbu iconfont"/>
  </el-backtop>
  </div>
</template>
<script>
import elementResizeDetectorMaker from 'element-resize-detector';
// 首页页面
export default {
  name: 'CommonPage',
  components: {
  },
  data() {
    return {
      isCollapse: false,
      isOpenType: true, // 是否打開狀態
      myInput: '', // 输入框内容
      language: sessionStorage.getItem('language'),

      tabList:  [{
        myName: '关于诺多康',
        isClose: false,
        path: 'CompanyInfo',
        child: [{
          myName:  '企业简介',
          path: 'CompanyIntro',
        }, {
          myName: '企业文化',
          path: 'CompanyCulture',
        }]
      }, {
        myName: '产品&解决方案',
        path: 'ProductList',
        isClose: false,
        child: []
      }, 
      {
        myName: '成功案例',
        path: 'CaseList',
        child: []
      }, {
        myName: '加入我们',
        path: 'JoinUs',
        child: []
      }, {
        myName: '联系我们',
        path: 'RelationUs',
        child: []
      }],
      productList: [],
      productShowList: [],
      changeType: "",
      appMuluOpen: false,
      openLeft: true, // 左侧导航是否展示
      newsList: [], // 公司新闻数量
      workList: []
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler (newData) {
        if (newData && newData.name === 'HomePage') {
          console.log('router>>>>>>>>>>', newData)
          if (this.$refs.indexRight) {
            this.$refs.indexRight.scrollTop = 0
          }
          this.typeChange(newData)
        }
      }
    }
  },

  computed: {
    tabNameList() {
      return  {
        '关于诺多康': this.$t('CompanyInfo'),
        '企业简介': this.$t('CompanyIntro'),
        '企业文化': this.$t('CompanyCulture'),
        '产品&解决方案': this.$t('ProductList'),

        '公司新闻': this.$t('JournalismList'),
        '企业新闻': this.$t('JournalismList1'),
        '行业动态': this.$t('JournalismList2'),
        '媒体聚焦': this.$t('JournalismList3'),
        '成功案例': this.$t('CaseList'),
        '加入我们': this.$t('JoinUs'),
        '联系我们': this.$t('RelationUs')
      }
    }
  },

  mounted () {
    var erd = elementResizeDetectorMaker(); //创建实例
        //监听id为box的元素 重绘echarts
    erd.listenTo(document.getElementById("indexBox"),(element)=>{
      var width = element.offsetWidth;
      console.log(width)
      // 判断宽度为小于1200
      if (width < 1200) {
        this.changeType = 'change1'
      }
    })

    // 默认中文 
    // 判断是否存在值
    if (!sessionStorage.getItem('language')) {
      this.setSessionItem("language", "zh");
      // sessionStorage.setItem('language', 'zh')
      this.$i18n.locale = 'zh'
      this.language = 'zh'
    } else {
      this.language = sessionStorage.getItem('language')
    }

    // 判断是否是app
    console.log('判断是否是app', sessionStorage.getItem('appChange'))
    if (sessionStorage.getItem('appChange') === 'true') {
      this.openLeft = false
    } else {
      this.openLeft = true
    }

    // this.init()
    let productList = sessionStorage.getItem('productList')
    if (productList && productList !== '') {
      this.init()
    }
    window.addEventListener("setItem", (e) => {
      console.log(e)
      this.init()
    });
  },

  methods: {
    // 跳转页面
    skipPage (data, type, index) {
      console.log(data)
      // 修改选中状态
      this.tabList.forEach(itemData => {
        // 选中状态修改
        this.$set(itemData, 'isActive', false)
        this.$set(itemData, 'isChildActive', false)
        if (itemData.myName === data.myName) {
          this.$set(itemData, 'isActive', true)
          // 展开子页面
          if (type === 'open') {
            this.$set(itemData, 'isClose', false)
          }
        }
        itemData.child.forEach(itemData1 => {
          this.$set(itemData1, 'isActive', false)
          if (itemData1.myName === data.myName) {
            this.$set(itemData1, 'isActive', true)
            // 子集选中，父级状态
            this.$set(itemData, 'isChildActive', true)
          }
        })
      })

      // 跳转参数
      let query = {
        title: data.myName,
        index: index,
      }

      // 判断是否有jourId参数
      if (data.jourId) {
        query.jourId = data.jourId
      }

      // 判断是否是app
      if (sessionStorage.getItem('appChange') === 'true') {
        this.openLeft = false
      }

      // 跳转页面
      this.$router.push({
        name: data.path,
        query: query
      })
    },

    // app端展示目录 
    changeMuluEvent () {
      this.appMuluOpen = !this.appMuluOpen
      this.openLeft  = !this.openLeft
    },

    // 跳转首页
    skipHomePage () {
      // 判断是否是app
      if (sessionStorage.getItem('appChange') === 'true') {
        this.openLeft = false
      }

      this.$router.push({
        name: 'HomePage'
      })
    },

    // 跳转页面
    skipOtherPage (path, name) {
      this.$router.push({
        name: path,
        query: {
          title: name
        }
      })
    },
  
    // 展开收起事件
    openOrCloseEvent(index) {
      this.tabList.forEach((itemData, indexData) => {
        if (index === indexData) {
          this.$set(itemData, 'isClose', !itemData.isClose)
        }
      })
    },

    handleClose(key, keyPath) { 
      console.log(key, keyPath);
    },

    // input回车事件
    inputChange (e) {
      console.log('inputChange', e)
    },

    // 状态修改事件
    typeChange (data) {
      // 修改选中状态
      this.tabList.forEach(itemData => {
        // 选中状态修改
        this.$set(itemData, 'isActive', false)
        this.$set(itemData, 'isChildActive', false)
        if (itemData.path === data.name) {
          this.$set(itemData, 'isActive', true)
        }

        // // 判断是案例详情
        if (data.name === 'CaseDetail' && itemData.path === 'CaseList') {
          this.$set(itemData, 'isActive', true)
        }

        itemData.child.forEach(itemData1 => {
          this.$set(itemData1, 'isActive', false)
          if (itemData1.path === data.name && itemData1.myName === data.query.title) {
            this.$set(itemData1, 'isActive', true)
            // 子集选中，父级状态
            this.$set(itemData, 'isChildActive', true)
          }
        })
      })
    },

    // 切换语言事件
    changeLangEvent () {
      console.log(this.$i18n.locale)
      if (sessionStorage.getItem('language') === 'zh') {
        // sessionStorage.setItem('language', 'en')
        this.setSessionItem("language", "en");
        this.$i18n.locale = 'en'
        this.language = 'en'
      } else {
        // sessionStorage.setItem('language', 'zh')
        this.setSessionItem("language", "zh");
        this.$i18n.locale = 'zh'
        this.language = 'zh'
      }
      this.init()
    },

    init () {
      // 加入我们模块处理
      this.workList = JSON.parse(sessionStorage.getItem('workList') || '[]')
      if (this.workList.length === 0) {
        
        this.tabList.forEach((item, index) => {
          if (item.myName === '加入我们') {
            this.tabList.splice(index, 1)
          }
        })
      }
      
      console.log('加入我们模块处理', this.tabList)

      // 新闻模块处理
      this.newsList = JSON.parse(sessionStorage.getItem('newsList'))
      if (this.newsList.length) {
        let child = []
        let numType1 = false
        let numType2 = false
        let numType3 = false
        this.newsList.forEach(item => {
          if (item.newsType === '企业新闻') {
            numType1 = true
          }
          if (item.newsType === '行业动态') {
            numType2 = true
          }
          if (item.newsType === '媒体聚焦') {
            numType3 = true
          }
        })

        if (numType1) {
          child.push({
            myName: '企业新闻',
            path: 'JournalismList',
            jourId: '1'
          })
        }
        if (numType2) {
          child.push({
            myName: '行业动态',
            path: 'JournalismList',
            jourId: '2'
          })
        }
        if (numType3) {
          child.push({
            myName: '媒体聚焦',
            path: 'JournalismList',
            jourId: '3'
          })
        }

        if (this.tabList[2].myName === '公司新闻') {
          this.tabList[2] = {
            myName: '公司新闻',
            isClose: false,
            path: 'JournalismList',
            child: child
          }
        } else {
          this.tabList.splice(2, 0, {
            myName: '公司新闻',
            isClose: false,
            path: 'JournalismList',
            child: child
          });
        }
      }


      // 产品模块处理
      let productList = JSON.parse(sessionStorage.getItem('productList'))
      let language = sessionStorage.getItem("language")
      this.tabList[1].child = []
      if (language === 'zh') {
        productList.forEach((item) => {
          this.tabList[1].child.push({
            myName: item.productName,
            path: 'ProductDetail'
          })
        })
      } else {
        productList.forEach((item) => {
          this.tabList[1].child.push({
            myName: item.productNameEng,
            path: 'ProductDetail'
          })
        })
      }
      console.log('productShowList', this.tabList)

      this.typeChange('')



    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-backtop, .el-calendar-table td.is-today{
  color: #DE1900;
}

.index-box{
  width: 100%;
  min-width: 1344px;
  height: 100%;
  overflow-x: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  .index-nav{
    width: calc(100% - 48px);
    height: 64px;
    background: #DE1900;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    .logo-box{
      width: 128px;
      margin: 18px 0;
      cursor: pointer;
      img{
        width: 100%;
      }
    }
    .index-nav-right {
      margin: 16px 0;
      display: flex;
      .icon-box{
        padding-left: 24px;
        height: 24px;
        margin: 4px 0 4px 24px;
        // border-left: 1px solid rgba(255,255,255,.5);

        .iconfont {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          cursor: pointer;
          width: 28px;
          display: inline-block;
        }
      }
    }
    .index-input {
      width: 220px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      background: rgba(255,255,255,.3);
      color: rgba(255,255,255,.9);
      padding: 0 12px 0 0;
      .iconfont {
        color: #fff;
        line-height: 32px;
        cursor: pointer;
      }
    }
    ::v-deep .el-input__inner{
      height: 32px;
      border-radius: 16px;
      border: none;
      background: transparent;
      color: rgba(255,255,255,.9);
    }
  }
  .index-left{
    width: 210px;
    background: #fff;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    height: calc(100vh - 64px);
    z-index: 1;
    transition-duration: .2s;
    .index-l-nav{
      height: 60px;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      line-height: 60px;
      background: #F6F7F9;
    }
    .index-l-nav-bth{
      cursor: pointer;
      &:hover {
        color: #DE1900;
      }
    }
  }
  .index-left-change {
    width: 68px;
    .menu-box{
      display: none;
    }
    .index-l-nav-title{
      display: none;
    }
  }
  .index-right {
    // width: calc(100% - 210px);
    flex: 1;
    background: #FAFAFC;
    height: calc(100vh - 64px);
    overflow-y: auto;
  }
  .index-content-box{
    display: flex;
    font-size: 14px;
    color: #666;
  }
}

.menu-box {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.menu-title{
  display: flex;
  justify-content: space-between;
  span{
    display: inline-block;
  }
  i {
    line-height: 40px;
    width: 24px;
    text-align: center;
    transform-origin: center center;
    transition-duration: .3s;
  }
}

.menu-item-content{
  padding: 0 20px 0 24px;
  font-size: 14px;
  color: #666;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(45deg, #DE1900 0%, #EC7D6F 100%);
    color: #fff;
  }
}
.menu-child-name{
  padding-left: 40px;
  background: #F4F6F9;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition-duration: .1s;
}
.isActive{
  color: #DE1900;
  background: #FCECEA;
}
.isActive-child{
  color: #DE1900;
}
.isChildActive{
  background: #FCECEA;
}
.isClose{
  height: 0;
}
.isRotate{
  transform: rotate(180deg);
}

.index-r-bottom{
  background: #F4F6F9;
  padding: 28px 58px 20px;
  height: 152px;
}

.index-r-hear{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(139,144,160,0.2000);
  .index-r-hear1{
    flex: 1;
    display: flex;
    .index-r-hear1-item {
      margin-right: 80px;
      div {
        margin-bottom: 16px;
        font-size: 14px;
        cursor: pointer;
        &:hover{ 
          color: #DE1900;
        }
      }
    }
  }
  .index-r-hear2{
    width: 218px;
    div {
      margin-bottom: 16px;
      font-size: 14px;
      cursor: pointer;
      &:hover{ 
        color: #DE1900;
      }
    }
  }
  .index-r-hear3{
    width: 154px;
    div {
      width: 88px;
      height: 88px;
      background: #fff;
      border-radius: 4px;
    }
    img{
      width: 72px;
      height: 72px;
      margin: 8px;
      background: #368fff;
    }
  }
}
.index-r-hear-change{
  border-bottom: none;
  margin-bottom: 0;
}
.index-r-ys{
  width: 100%;
  padding: 16px 0;
  text-align: center;
  background: #DE1900;
  color: #fff;
  font-size: 12px;
  span{
    display: inline-block;
    &::after{
      display: inline-block;
      width: 1px;
      height: 16px;
      background: rgba(255, 255, 255, .5);
      content: '';
      margin: 0 8px;
      position: relative;
      top: 4px;
    }
    &:last-child::after{
      display: none;
    }
  }
}
.icon-huidaodingbu{
  font-size: 22px;
  position: relative;
  top: 2px;
}

.app-mulu{
  display: none;
  i{
    font-size: 24px;
    color: #fff;
  }
}
.index-r-top{
  min-height: 60%;
}
</style>
