<template>
  <div class="company-box company-intro">
    <div class="company-item">
      <div class="company-title">
        <!-- 企业文化 -->
        {{ $t('CompanyCulture') }}
      </div>

      <div class="company-content">
        <div 
          v-for="(item, index) in companyList"
          :key="index"
          :id="'content' + index"
          >
          <DetailFrame
            :CulTitle="item.name"
            :CulImgList="item.img"
            :CulContent="item.content"
            :CulIconList="item.CulIconList"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// 企业文化
import DetailFrame from '@/components/detail-frame.vue'
export default {
  name: 'company-culture',
  components: {
    DetailFrame
  },
  watch: {
    $route: {
      deep: true,
      immediate:true,
      handler (newData) {
        if (newData && newData.query.curltureId !== undefined) {
          console.log(newData.query.curltureId)
            this.$nextTick(() => {
              document.querySelector("#content" + newData.query.curltureId).scrollIntoView(true)
            })
        }
      }
    }
  },
  computed: {
    companyList () {
      return [{
        name: this.$t('BrandCulture'),
        content: this.$t('BrandCulture1Content'),
        img: [require('@/assets/images/culture-img1.jpg')],
        CulIconList: [{
          icon: require('@/assets/images/culture-icon1.png'),
          name: this.$t('BrandCulture2'),
          content: this.$t('BrandCulture2Small'),
          mainContent: this.$t('BrandCulture2Content')
        }, {
          icon: require('@/assets/images/culture-icon2.png'),
          name: this.$t('BrandCulture3'),
          content: this.$t('BrandCulture3Small'),
          mainContent: this.$t('BrandCulture3Content')
        }, {
          icon: require('@/assets/images/culture-icon3.png'),
          name: this.$t('BrandCulture4'),
          content: this.$t('BrandCulture4Small'),
          mainContent: this.$t('BrandCulture4Content'),
        }]
      }, {
        name: this.$t('BrandSpirit'),
        CulIconList: [{
          icon: require('@/assets/images/culture-icon4.png'),
          name: this.$t('BrandSpirit'),
          mainContent: this.$t('CollaborationContent'),
        }, {
          icon: require('@/assets/images/culture-icon5.png'),
          name: this.$t('pragmatic'),
          mainContent: this.$t('pragmaticContent'),
        }, {
          icon: require('@/assets/images/culture-icon6.png'),
          name: this.$t('HighEfficiency'),
          mainContent: this.$t('HighEfficiencyContent'),
        }, {
          icon: require('@/assets/images/culture-icon7.png'),
          name: this.$t('flexible'),
          mainContent: this.$t('flexibleContent'),
        }]
      }, {
        name: this.$t('BrandValues'),
        content: this.$t('BrandValues1'),
        img: [require('@/assets/images/culture-img2.jpg')],
        CulIconList: [{
          icon: require('@/assets/images/culture-icon8.png'),
          name: this.$t('BrandValues2'),
          mainContent: this.$t('BrandValues2Content'),
        }, {
          icon: require('@/assets/images/culture-icon9.png'),
          name: this.$t('BrandValues3'),
          mainContent: this.$t('BrandValues3Content'),
        }, {
          icon: require('@/assets/images/culture-icon10.png'),
          name: this.$t('BrandValues4'),
          mainContent: this.$t('BrandValues4Content'),
        }]
      }, {
        name: this.$t('BrandPhilosophy'),
        content: this.$t('BrandPhilosophy1'),
        img: [require('@/assets/images/culture-img3.jpg')],
        CulIconList: [{
          icon: require('@/assets/images/culture-icon11.png'),
          name: this.$t('BrandPhilosophy2'),
          content: this.$t('BrandPhilosophy11'),
          mainContent: this.$t('BrandPhilosophy2Content'),
        }, {
          icon: require('@/assets/images/culture-icon12.png'),
          name: this.$t('BrandPhilosophy3'),
          content: this.$t('BrandPhilosophy12'),
          mainContent: this.$t('BrandPhilosophy3Content'),
        }, {
          icon: require('@/assets/images/culture-icon13.png'),
          name: this.$t('BrandPhilosophy4'),
          content: this.$t('BrandPhilosophy13'),
          mainContent: this.$t('BrandPhilosophy4Content'),
        }]
      }]
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.company-box{
  .company-item{
    margin-top: 60px;
  }
  .company-title {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    text-align: center;

    &::before {
      content: '';
      display: inline-block;
      width: 200px;
      height: 2px;
      margin-right: 40px;
      position: relative;
      top: -8px;
      background: linear-gradient(90deg, rgba(236,125,111,0) 0%, rgba(222,25,0,0.8000 ) 100%);
    }

    &::after {
      content: '';
      display: inline-block;
      width: 200px;
      height: 2px;
      position: relative;
      top: -8px;
      margin-left: 40px;
      background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%);
    }
  }
  .company-content{   
    margin: 60px 120px 36px;
  }
}
</style>
