'use strict';

/* eslint-disable no-unused-vars */

/** 有几个模块，subList就有几个元素
 * @param: name 子系统名称
 * @param: url 子系统使用的环境变量（请求地址，便于不同模块请求不同服务器的配置，可在三个环境中新增类似VUE_APP_BASE_API的域名地址变量）
 */
const subList = [
  {
    name: 'API_LIST_USER',
    // url: 'http://api.nodkare.com/api',
    url: 'http://api.nodkare.com/api'
  },
];

// 所有api子系统
let API_LIST = {};
const moduleFiles = require.context('./modules', true, /\.js$/);
moduleFiles.keys().forEach((modulePath) => {
  API_LIST = { ...API_LIST, ...moduleFiles(modulePath).default };
});

// 合成API地址集合
let apiList = {};
subList.forEach((it) => {
  let subApi = Object.entries(API_LIST[it.name]).reduce(
    (target, [key, value]) => {
      target[key] = it.url + '/' + value.replace(/^\W*/, '');
      return target;
    },
    {}
  );
  apiList = { ...apiList, ...subApi };
});

export default apiList;