import Vue from 'vue'
import App from './App.vue'

import router from '@/router/index.js'

Vue.config.productionTip = false

// element组件引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

// 图标引入
import '@/assets/iconfont/iconfont.css'

//引入vue-i18n
import VueI18n from 'vue-i18n'
//引入Element的语言包
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: sessionStorage.getItem('language'),
  // locale: 'zh',
  messages:{
    'zh':Object.assign(require('./common/lang/zh.js'),zhLocale),//将我们项目中的语言包与Element的语言包进行合并
    'en':Object.assign(require('./common/lang/en.js'),enLocale),
  },
});
Vue.use(ElementUI, {
  i18n:(key,value) =>i18n.t(key,value) //重点！！在注册Element时设置i18n的处理方法（这里有个小坑）
})


// Vue.prototype.$addStorageEvent = function (type, key, data) {
//   if (type === 1) {
//     // 创建一个StorageEvent事件
//     let newStorageEvent = document.createEvent('StorageEvent');
//     const storage = {
//         setItem: function (k, val) {
//             localStorage.setItem(k, val);
//             // 初始化创建的事件
//             newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
//             // 派发对象
//             window.dispatchEvent(newStorageEvent);
//         }
//     }
//    return storage.setItem(key, data);
//   } else {
//     // 创建一个StorageEvent事件
//     let newStorageEvent = document.createEvent('StorageEvent');
//     const storage = {
//         setItem: function (k, val) {
//            sessionStorage.setItem(k, val);
//             // 初始化创建的事件
//             newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
//             // 派发对象
//             window.dispatchEvent(newStorageEvent);
//         }
//     }
//     return storage.setItem(key, data);
//   }
// }


// 注册自定义插件
import moduleFiles from './plugins';
// 引入api地址
import apiList from '@/api';

// 批量使用自定义插件
moduleFiles.keys().forEach(modulePath => {
  Vue.use(moduleFiles(modulePath).default);
});

// 注册所有api地址为全局变量
Vue.prototype.$_API = apiList;

Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);

      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
}

new Vue({
  render: h => h(App),
  i18n,
  router: router,
}).$mount('#app')
