<template>
  <div class="product_content">
    <div class="product_mainTitle">{{ $t('ProductList') }}</div>
    <div class="product_two">
      <div 
        v-for="(item, index) in itemShowList"
        :key="index"
        class="product_two_item"
        @click="skipProdEvent(item.name, index)">
        <div class="product_two_img">
          <img :src="item.image" alt="">
        </div>
        <div class="product_two_right">
          <div 
            class="product_two_name text_hiddle_1"
            :title="item.name">
            {{ item.name }}
          </div>
          <div 
            class="product_two_EleName text_hiddle_1"
            :title="item.EleName">
            {{ item.EleName }}
          </div>
          <div class="product_two_list">
            <div 
              v-for="(item1, index1) in item.child"
              :key="index * 10 +index1">
              {{ item1.name }}
            </div>
          </div>
        </div>
        <i class="icon-you iconfont"></i>
      </div>
    </div>
  </div>
</template>

<script>
// 产品列表
export default {
  name: 'product-list',
  components: {
  },
  data () {
    return {
      itemShowList: [],
      productList: [],
      language: ''
    }
  },
  watch: {

  },
  computed: {

 },

  mounted () {
    // 添加对 sessionStorage 的监听
    this.productList = JSON.parse(sessionStorage.getItem('productList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    });

  },

  methods: {
    init () {
      let language = sessionStorage.getItem("language")
      this.itemShowList = []
      if (language === 'zh') {
        this.productList.forEach((item, index) => {
          this.itemShowList.push({
            name: item.productName,
            EleName: item.productNameEng.toUpperCase(),
            image: item.list && item.list.length && item.list[0].picList[0],
            child: []
          })

          item.list.forEach((item1, index1) => {
            this.itemShowList[index].child.push({
              name: '系列' + (index1 + 1) + '： ' + item1.detailTitle
            })
          })
        })
      } else {
        this.productList.forEach((item, index) => {
          this.itemShowList.push({
            name: item.productNameEng,
            EleName: item.productNameEng.toUpperCase(),
            image: item.list && item.list.length && item.list[0].picList[0],
            child: []
          })

          item.list.forEach((item1, index1) => {
            this.itemShowList[index].child.push({
              name: 'Series' + (index1 + 1) + '： ' + item1.detailTitleEng
            })
          })
        })
      }
    },

    skipProdEvent (title, index) {
      this.$router.push({
        name: 'ProductDetail',
        query: {
          title: title,
          index: index
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel_img{
  height: 400px;
  width: 100%;
  background: #ccc;
}
.product_mainTitle{
  margin-top: 60px;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    width: 200px;
    height: 6px;
    margin-right: 40px;
    position: relative;
    top: -8px;
    background: url(@/assets/images/title-left.png);
  }

  &::after {
    content: '';
    display: inline-block;
    width: 200px;
    height: 6px;
    position: relative;
    top: -8px;
    margin-left: 40px;
    background-size: 100% 100%;
    background: url(@/assets/images/title-right.png);
  }
}

.product_two{
  background: #F9FAFC;
  padding: 0 120px;
  margin-top: 60px;
}

.product_two_item {
  width: 100%;
  height: 280px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid rgba(139,144,160,0.2000);
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: left;
  margin-bottom: 60px;
  transition-duration: .3s;
  position: relative;
  .product_two_img {
    width: 460px;
    height: 280px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product_two_right {
    // flex: 1;
    display: block;
    padding: 40px;
    width: calc(100% - 490px);
    min-width: 0;
  }
  .product_two_name{
    font-size: 24px;
    color: #333;
    line-height: 32px;
    font-weight: 500;
  }
  .product_two_EleName{
    font-size: 12px;
    color: #8B90A0;
    line-height: 22px;
    margin-top: 8px;
  }
  .product_two_list{
    color: #333;
    margin-top: 24px;
    div{
      margin-bottom: 8px;
    }
  }
  .icon-you{
    color: #DE1900;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition-duration: .3s;
  }
  &:hover {
    box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.0600);
    background: url('@/assets/images/hover-bg3.png');
    background-size: 100% 100%;
    .icon-you {
      opacity: 1;
    }
  }
}
</style>
