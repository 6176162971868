<template>
  <div class="company-box company-intro">
    <div class="company-item">
      <div class="company-title">
        <!-- 企业简介 -->
        {{ $t('CompanyIntro') }}
      </div>
      <div class="company-content">
        <div class="company-img-box">
          <img class="company-img" src="@/assets/images/company-info.png" alt="">
        </div>
        <div class="company-text text_hiddle_11">
          {{ $t('BrandIntroduction') }}
          <br>
          <br>
          {{ $t('BrandIntroduction1') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 企业简介
export default {
  name: 'company-info',
  components: {
  },
  data() {
    return {
      companyList: [{
        name: '品牌文化',
        content: '品牌文化立足于诺多康发展历程，着眼于智慧医疗行业，对内增强企业凝聚力、对外扩大品牌影响力。',
      }, {
        name: '品牌精神',
        content: '协同、务实、高效、灵活。',
      }, {
        name: '品牌价值观',
        content: '诺多康品牌价值观根植于企业内部，是引领企业进行一切经营活动的指导性原则。',
      }, {
        name: '品牌哲学',
        content: '诚实守信，实现稳定发展；合作共赢，实现多元发展；科技创新，驱动快速发展。',
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.company-box{
  .company-item{
    margin-top: 60px;
  }
  .company-title {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    text-align: center;

    &::before {
      content: '';
      display: inline-block;
      width: 200px;
      height: 2px;
      margin-right: 40px;
      position: relative;
      top: -8px;
      background: linear-gradient(90deg, rgba(236,125,111,0) 0%, rgba(222,25,0,0.8000 ) 100%);
    }

    &::after {
      content: '';
      display: inline-block;
      width: 200px;
      height: 2px;
      position: relative;
      top: -8px;
      margin-left: 40px;
      background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%);
    }
  }
  .company-content{
    margin-top: 60px;
    margin: 60px 124px 36px;
    .company-img-box{
      width: 100%;
      background: #141211;
      position: relative;
    }
    .company-img {
      width: 100%;
    }
    .company-text{
      line-height: 24px;
      font-size: 16px;
      line-height: 24px;
      margin-top: 40px;
      text-align: justify;
    }
  }
  .company-list{
    display: flex;
    justify-content: center;
    margin: 60px 0 120px;
  }
  .company-list-item {
    width: 176px;
    height: 148px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(139,144,160,0.2000); 
    padding: 20px;
    margin: 0 20px;
    cursor: pointer;
    .company-list-name{
      font-size: 24px;
      line-height: 32px;
      color: #333; 
    }
    .company-list-content{
      margin-top: 20px;
      font-size: 14px;
      line-height: 24px;
      color: #666; 
    }
  }
}
</style>
