var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-breadcrumb',{staticClass:"breadcrumb-class",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/JournalismList',
      query: {
        title: '公司新闻',
      }
      }}},[_vm._v(" "+_vm._s(_vm.$t('JournalismList'))+" ")]),_c('el-breadcrumb-item',{attrs:{"to":{
        path: '/JournalismList', 
        query: {
          title: _vm.jourName,
          jourId: _vm.jourId
        } 
      }}},[(_vm.jourId === 1)?_c('span',[_vm._v(_vm._s(_vm.$t('JournalismList1')))]):_vm._e(),(_vm.jourId === 2)?_c('span',[_vm._v(_vm._s(_vm.$t('JournalismList2')))]):_vm._e(),(_vm.jourId === 3)?_c('span',[_vm._v(_vm._s(_vm.$t('JournalismList3')))]):_vm._e()]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$t('JournalismList4')))])],1),_c('div',{staticClass:"detail-box"},[_c('div',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.newsContent.newsName))]),_c('div',{staticClass:"detail-title-2"},[_vm._v(_vm._s(_vm.$t('ReleaseTime'))+" ："+_vm._s(_vm.parseTime(_vm.newsContent.createTime)))]),_c('div',{staticClass:"detail-content",domProps:{"innerHTML":_vm._s(_vm.newsContent.newRichStr)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }