<template>
  <div class="culture-box">
    <div class="culture-item">
      <div class="culture-title">
        <i class="icon-erjiyemianbiaoti iconfont"/>
        {{ CulTitle }}
      </div>

      <!-- 判断是多张图，展示轮播图片 -->
      <div 
        v-if="CulImgList.length > 1"
        class="culture-img-list">
        <el-carousel
          height="400px">
          <el-carousel-item v-for="item in CulImgList" :key="item">
            <img class="carousel_img" :src="item" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <img 
        v-if="CulImgList.length === 1" 
        :src="CulImgList[0]" 
        alt=""
        class="culture-img">

      <!-- 文本展示 -->
      <div v-if="CulContent !== ''">{{ CulContent }}</div>
      <!-- 底部图标展示 -->
      <div 
        v-if="CulIconList.length"
        class="culture-list">
        <div 
          v-for="(item, index) in CulIconList"
          :key="index"
          :style="{'width': 100 / CulIconList.length + '%'}"
          class="culture-list-item">
          <div class="culture-list-detail">
            <img :src="item.icon" alt="">
            <div class="culture-list-name text_hiddle_1">{{ item.name }}</div>
            <div 
              v-if="item.content" 
              class="culture-list-content text_hiddle_4">
              {{ item.content }}
            </div>
          </div>
          <div class="culture-list-hover">
            <div class="culture-list-hover-name">{{ item.name }}</div>
            <div class="culture-list-hover-mainContent">{{ item.mainContent }}</div>
          </div>
        </div>
      </div>

      <!-- 插槽 -->
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
// 企业文化框架组件
export default {
  name: 'culture-info',
  props: {
    CulTitle: {
      type: String,
      default: ''
    },
    CulContent: {
      type: String,
      default: ''
    },
    CulImgList: {
      type: Array,
      default: () => []
    },
    CulIconList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.culture-box{
  .culture-item{
    margin-top: 50px;
    padding-top: 10px;
  }
  .culture-title {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 32px;
    text-align: left;
    margin: 0 0 40px;
    .icon-erjiyemianbiaoti{
      font-size: 22px;
      color: #DE1900;
    }
  }
  .culture-img {
    min-height: 200px;
    width: 100%;
    margin-bottom: 40px;
  }
  .culture-img-list{
    background: #368fff;
    margin-bottom: 40px;
  }
  .culture-content{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .culture-text{
      flex: 1;
      padding: 32px 120px 0px 20px;
      line-height: 24px;
    }
  }
  .culture-list{
    display: flex;
    justify-content: center;
    margin: 40px 0 0;
  }
  .culture-list-item {
    min-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    .culture-list-hover {
      opacity: 0;
      transition-duration: .5s;
      background: #FAFAFC;
      background-image: url('@/assets/images/hover-bg2.png');
      background-size: 100% 100%;
      width: calc(100% - 44px);
      height: 100%;
      position: absolute;
      padding: 0 22px;
    }

    .culture-list-hover-name{
      font-size: 16px;
      font-weight: 500;
      color: #DE1900;
      line-height: 24px;
      margin-top: 28px;
    }
    .culture-list-hover-mainContent{
      margin-top: 20px;
      font-size: 14px;
      line-height: 24px;
      color: #666; 
      // text-align: justify;
    }

    .culture-list-name{
      font-size: 16px;
      line-height: 24px;
      color: #333; 
      text-align: center;
      font-weight: 500;
    }
    .culture-list-content{
      margin-top: 12px;
      font-size: 12px;
      line-height: 24px;
      padding: 0 12px;
      color: #666; 
      text-align: center;
    }
    &::after{
      display: inline-block;
      content: '';
      width: 1px;
      height: 300px;
      background: linear-gradient(180deg, rgba(191,195,206,0.1000) 0%, #8B90A0 51%, rgba(191,195,206,0.1000) 100%);
      opacity: 0.5;
      position: absolute;
      top: 0;
      right: 0;
    }
    &:last-child::after {
      display: none;
    }

    &:hover {
      .culture-list-hover {
        opacity: 1;
      }
    }
  }
}
.culture-list-detail{
  img{
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto 24px;
  }
}
.carousel_img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
