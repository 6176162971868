<template>
  <div class="default-img">
    <div class="default-img-content">
      <img src="@/assets/images/no-data.png" alt="">
      <div class="default-img-title">{{ $t('noData') }}</div>
    </div>
  </div>
</template>

<script>
// 企业文化框架组件
export default {
  name: 'default-img',
  props: {

  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.default-img{
  width: 100%;
  height: 240px;
  position: relative;
  .default-img-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .default-img-title{
    text-align: center;
  }
}
</style>
