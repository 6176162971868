<template>
  <div>
    <el-breadcrumb 
      class="breadcrumb-class"
      separator="/">
      <el-breadcrumb-item 
        :to="{ path: '/JournalismList',
        query: {
          title: '公司新闻',
        }
        }">
        {{ $t('JournalismList') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item 
        :to="{
          path: '/JournalismList', 
          query: {
            title: jourName,
            jourId: jourId
          } 
        }">
        <span v-if="jourId === 1">{{ $t('JournalismList1') }}</span>
        <span v-if="jourId === 2">{{ $t('JournalismList2') }}</span>
        <span v-if="jourId === 3">{{ $t('JournalismList3') }}</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('JournalismList4') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail-box">
      <div class="detail-title">{{ newsContent.newsName }}</div>
      <div class="detail-title-2">{{ $t('ReleaseTime') }} ：{{ parseTime(newsContent.createTime) }}</div>
      <div 
        v-html="newsContent.newRichStr"
        class="detail-content">
        
      </div>
    </div>
  </div>
</template>

<script>
// 新闻详情页面
export default {
  name: 'journalism-detail',
  components: {
  },
  data() {
    return {
      jourId: '',
      jourName: '',
      newsList: [],
      newsContent: {}
    }
  },
  mounted () {
    this.jourId = this.$route.query.jourId
    this.jourName = this.$route.query.jourName

    // 添加对 sessionStorage 的监听
    
    this.newsList = JSON.parse(sessionStorage.getItem('newsList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    });
  },

  methods: {
    init () {
      this.newsList.forEach(item => {
        if (this.$route.query.newsId === item.newsId) {

          let language = sessionStorage.getItem("language")
          if (language === 'zh') {
            this.newsContent = {
              newsId: item.newsId,
              newsName: item.newsName,
              newIntroduction: item.newIntroduction,
              createTime: item.createTime,
              newsKeyword: item.newsKeyword,
              newRichStr: item.newRichStr
            }
          } else {
            this.newsContent = {
              newsId: item.newsId,
              newsName: item.newsNameEng,
              newIntroduction: item.newIntroductionEng,
              createTime: item.createTime,
              newsKeyword: item.newsKeywordEng,
              newRichStr: item.newRichEngStr
            }
          }
        }
      })
    },

    // 时间戳转为正常时间的公共方法，当然你也可以加上小时、分、秒
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-class{
  background: #fff;
  padding: 16px 32px;
  position: fixed;
  width: 100%;
  top: 64px;
  border-bottom: 1px solid #E8E8E8;
  ::v-deep .el-breadcrumb__inner.is-link{
    color: #8B90A0 !important;
    font-weight: 500 !important;
    &:hover {
      color: #DE1900 !important;
    }
  }
  ::v-deep .el-breadcrumb__separator{
    font-weight: 500;
  }
  ::v-deep .el-breadcrumb__inner{
    color: #DE1900 !important;
  }
}
.detail-box{
  font-size: 16px;
  line-height: 24px;
  color: #333;
  margin: 0 124px 80px;
  .detail-title {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    margin-top: 106px;
  }
  .detail-title-2 {
    font-size: 14px;
    font-weight: 400;
    color: #bfbfbf;
    line-height: 32px;
    margin-top: 20px;
  }
  .detail-content{
    margin-top: 40px;
    width: 100%;
    ::v-deep p {
      white-space: pre;
    }
    ::v-deep img {
      width: 100%;
      margin: 20px 0;
    }
  }
}
</style>
