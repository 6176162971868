<template>
  <div class="first_box">
    <!-- 轮播图 -->
    <el-carousel 
      height="400px">
      <el-carousel-item v-for="(item, index) in bannarList" :key="index">
      <!-- @click="skipDetailPage" -->
        <div >
          <img class="carousel_img" :src="item.url" alt="">
          <!-- 新闻标题 -->
          <div class="carousel_content">
            <!-- <div class="carousel_content-text">这里是标题</div> -->
            <!-- <div class="carousel_content-bth">
              {{ $t('More') }}
              <i class="icon-you iconfont"></i>
            </div> -->
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 关于诺多康 -->
    <div class="first_content">
      <div class="first_mainTitle">{{ $t('CompanyInfo') }}</div>
      <div class="first_smallTitle app-hidden">
        <!--  协同 务实 高效 灵活 -->
        {{ $t('Collaboration') }}
        {{ $t('pragmatic') }}
        {{ $t('HighEfficiency') }}
        {{ $t('flexible') }}
      </div>
      <div class="first_one">
        <img 
          class="first_one_img" 
          src="@/assets/images/company-info.png" 
          alt="">
        <div
          class="first_one_text">
          <div class="first_one_text1">{{ $t('CompanyIntro') }}</div>
          <!-- 公司简介 -->
          <div class="first_one_text2 text_hiddle_6">{{ $t('BrandIntroduction') }}</div>
          <div 
            @click="skipPage('CompanyInfo', '关于诺多康')"
            class="first_one_text3">
            <!-- 了解更多 -->
            {{ $t('MoreAbout') }}
            <i class="el-icon-arrow-right"/>
          </div>
        </div>
      </div>
    </div>

    <div class="first_content">
      <div class="first_mainTitle">{{ $t('ProductList') }}</div>
      <div class="first_two">
        <div class="first_two_app_box">
          <div
            class="first_two_app"
            :class="{
              'first_two_app-change': appIndex === index
            }"
            v-for="(item, index) in itemShowList"
            :key="index"
            @click="appIndex = index">
            {{ item.name }}
          </div>
        </div>
        <div 
          v-for="(item, index) in itemShowList"
          :key="index"
          :class="{
            'first_two_item-change': index === 0,
            'first_two_itemApp-change': appIndex === index
          }"
          class="first_two_item"
          @click="skipProdEvent(item.name, index)">
          <img class="first_two_img" :src="item.image" alt="">
          <div class="first_two_bg"/>
          <div class="first_two_name text_hiddle_4">{{ item.name }}</div>
          <div class="first_two_EleName text_hiddle_4">{{ item.EleName }}</div>
          <i class="iconfont icon-xingzhuangjiehe"/>
          <div class="first_two_child-box">
            <div 
              v-for="(item1, index1) in item.child"
              :key="index1"
              class="first_two_child">
              {{ item1.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="false"
      class="first_content">
      <div class="first_mainTitle">{{ $t('JournalismList') }}</div>
      <div 
        @click="skipPage('JournalismList', '公司新闻')"
        class="first_smallTitle">
        <!-- 了解更多 -->
        {{ $t('MoreAbout') }}
        <i class="iconfont icon-you"/>
      </div>
      <div
        v-if="newContentList.length" 
        class="first_three">
        <div 
          v-for="(item, index) in newContentList"
          :key="index"
          class="first_three_item"
          @click="skipDetailPage">
          <img class="first_three_img" src="@/assets/images/bannar2.png" alt=""/>
          <div class="first_three_right">
            <div class="first_three_title text_hiddle_1">新闻标题新闻标题新闻标题新闻标题新闻标题</div>
            <div class="first_three_small">
              <div 
                v-for="(item1, index1) in 3"
                :key="index * 10 + index1">
                标签1
              </div>
            </div>
            <div class="first_three_content text_hiddle_4">新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容</div>
            <div class="first_three_time">2022-11-11 18:32</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="first_three">
        <DefaultImg/>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultImg from '@/components/default-img.vue'
// 首页页面
export default {
  name: 'home-page',
  components: {
    DefaultImg
  },
  data() {
    return {
      bannarList: [{
        url: require('@/assets/images/prod-img2.png')
      }, {
        url: require('@/assets/images/prod1-img2.jpg')
      }, {
        url: require('@/assets/images/prod2-img4.png')
      }, {
        url: require('@/assets/images/prod3-img3.png')
      }],
      newContentList: [{},{}],
      appIndex: 0,
      productList: [],  // 产品
      itemShowList: []
    }
  },

  mounted () {
    // 添加对 sessionStorage 的监听
    this.productList = JSON.parse(sessionStorage.getItem('productList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    })
  },

  computed: {
    itemList () {
      return [{
        name: this.$t('productName1'),
        EleName: 'INTELLIGENT \n MEDICAL \n CARE ',
        image: require('@/assets/images/product-img1.png'),

        child: [{
          name: this.$t('Series1') + '： ' + this.$t('Smartealthcare1'),
        }, {
          name: this.$t('Series2') + '： ' + this.$t('Smartealthcare2'),
        }]
      }, {
        name: this.$t('productName2'),
        EleName: 'THE WHOLE \n SOLUTION OF \n MEDICAL \n PURIFICATION',
        image: require('@/assets/images/product-img2.png'),

        child: [{
          name: this.$t('Series1') + '： ' + this.$t('Overall1'),
        }, {
          name: this.$t('Series2') + '： ' + this.$t('Overall2'),
        }, {
          name: this.$t('Series3') + '： ' + this.$t('Overall3'),
        }]
      }, {
        name: this.$t('productName3'),
        EleName: 'AFTER-SALE \n MANAGEMENT \n & MANINTENANG \n OF MEDICAL \n ENGINEERING',
        image: require('@/assets/images/product-img3.png'),

        child: [{
        name: this.$t('Series1') + '： ' + this.$t('Medical1'),
        }, {
          name: this.$t('Series2') + '： ' + this.$t('Medical2'),
        }, {
          name: this.$t('Series3') + '： ' + this.$t('Medical3'),
        }, {
          name: this.$t('Series4') + '： ' + this.$t('Medical4'),
        }]
      }, {
        name: this.$t('productName4'),
        EleName: 'THE SOLUTION OF \n ENVIRONMENTAL \n PROTECTION SYSTEM',
        image: require('@/assets/images/product-img4.png'),

        child: [{
          name: this.$t('Series1') + '： ' + this.$t('Environmental1'),
        }, {
          name: this.$t('Series2') + '： ' + this.$t('Environmental2'),
        }]
      }, {
        name: this.$t('productName5'),
        EleName: 'BIOPHARMACEUTICAL \n AND RAW  MATERIAL \n RESEARCH AND \n DEVELOPMENT',
        image: require('@/assets/images/product-img5.png'),

        child: [{
          name: this.$t('Series1') + '： ' + this.$t('Materials1'),
        }, {
          name: this.$t('Series2') + '： ' + this.$t('Materials2'),
        }, {
          name: this.$t('Series3') + '： ' + this.$t('Materials3'),
        }]
      }]
    } 
  },

  methods: {
    init () {
      let language = sessionStorage.getItem("language")
      this.itemShowList = []
      if (language === 'zh') {
        this.productList.forEach((item, index) => {
          this.itemShowList.push({
            name: item.productName,
            EleName: item.productNameEng.toUpperCase(),
            image: item.list && item.list.length && item.list[0].picList[0],
            child: []
          })

          item.list.forEach((item1, index1) => {
            this.itemShowList[index].child.push({
              name: '系列' + (index1 + 1) + '： ' + item1.detailTitle
            })
          })
        })
      } else {
        this.productList.forEach((item, index) => {
          this.itemShowList.push({
            name: item.productNameEng,
            EleName: item.productNameEng.toUpperCase(),
            image: item.list && item.list.length && item.list[0].picList[0],
            child: []
          })

          item.list.forEach((item1, index1) => {
            this.itemShowList[index].child.push({
              name: 'Series' + (index1 + 1) + '： ' + item1.detailTitleEng
            })
          })
        })
      }
    },

    skipPage (path, title) {
      console.log(path)
      this.$router.push({
        name: path,
        query: {
          title: title
        }
      })
    },

    skipDetailPage (){
      this.$router.push({
        name: 'JournalismDetail',
        query: {
          jourName: '企业新闻',
          jourId: 1
        }
      })
    },

    skipProdEvent (title, index) {
      this.$router.push({
        name: 'ProductDetail',
        query: {
          title: title,
          index: index
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel_img{
  height: 400px;
  width: 100%;
  background: #ccc;
  object-fit: cover;
}
.first_mainTitle{
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  margin-top: 60px;
  text-align: center;
}
.first_smallTitle{
  font-size: 14px;
  font-weight: 400;
  color: #DE1900;
  line-height: 22px;
  text-align: center;
  margin-top: 12px;
  cursor: pointer;
  .icon-you{
    font-size: 13px;
  }
}
.first_one{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.first_one_img{
  width: 500px;
  height: 270px;
}
.first_one_text{
  padding: 36px 20px 20px;
  background: #F9FAFC;
  position: relative;
}
.first_one_text1{
  font-size: 16px;
  color: #333;
  font-weight: 500px;
  font-weight: bold;
}
.first_one_text2{
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-top: 16px;
  padding-right: 100px;
  // text-align: justify;
}
.first_one_text3 {
  color: #DE1900;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  right: 120px;
  cursor: pointer;
}
.first_two{
  background: #F9FAFC;
  height: 240px;
  padding: 0 120px;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  &:hover {
    .first_two_item-change {
      width: 170px;
      background: rgba(255,255,255,0.6800);
      .first_two_name{
        color: #333;
        white-space: pre-line;
      }
      .first_two_EleName{
        color: #8B90A0;
        white-space: pre-line;
      }
      .icon-xingzhuangjiehe{
        opacity: 1;
      }
      .first_two_img{
        opacity: 0;
      }
      .first_two_bg{
        background: #fff;
      }
    }
  }
}

.first_two_item {
  width: 170px;
  background: rgba(255,255,255,0.6800);
  backdrop-filter: blur(9px);
  border-right: 1px solid #f2f2f2;
  padding: 20px;
  cursor: pointer;
  position: relative;
  transition-duration: .3s;
  overflow: hidden;
  .first_two_name{
    font-size: 16px;
    color: #333;
    line-height: 24px;
    white-space: pre-line;
    position: relative;
    z-index: 5;
  }
  .first_two_EleName{
    font-size: 12px;
    color: #8B90A0;
    line-height: 22px;
    white-space: pre-line;
    margin-top: 8px;
    position: relative;
    z-index: 5;
  }
  .icon-xingzhuangjiehe{
    color: #DE1900;
    position: absolute;
    right: 20px;
    bottom: 20px;
    opacity: 1;
    z-index: 5;
  }
  .first_two_img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
  }
  &:hover {
    width: 300px !important;
    
    .first_two_name{
      color: #fff !important;
    }
    .first_two_EleName{
      color: #fff !important;
      white-space: initial !important;
    }
    .icon-xingzhuangjiehe{
      opacity: 0 !important;
    }
    .first_two_img{
      opacity: 1 !important;
    }
    .first_two_bg{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%) !important;
    }
  }
}

// 首個頁面默認樣式
.first_two_item-change{
  width: 300px;
  .first_two_name{
    color: #fff;
    white-space: nowrap;
  }
  .first_two_EleName{
    color: #fff;
    white-space: initial;
  }
  .icon-xingzhuangjiehe{
    opacity: 0;
  }
  .first_two_img{
    opacity: 1;
  }
  .first_two_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%);
  }
}
.first_three{
  background: #fff;
  margin-top: 32px;
  cursor: pointer;
  .first_three_item{
    &:last-child{
      border-bottom: none;
    }
  }

}
.first_three_item{
  padding: 24px 0;
  margin: 0 120px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;
  .first_three_img{
    width: 300px;
    height: 180px;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;
  }
  .first_three_right{
    margin-left: 20px;
    flex: 1;
    position: relative;
  }
  .first_three_title{
    color: rgba(0,0,0,.85);
    font-size: 16px;
    font-weight: bold;
  }
  .first_three_small{
    margin-top: 16px;
    div{
      display: inline-block;
      font-size: 12px;
      margin-right: 8px;
      padding: 0 8px;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      color: #DE1900;
      border: 1px solid #DE1900;
    }
  }
  .first_three_content {
    margin-top: 14px;
    color: rgba(0,0,0,.65);
  }
  .first_three_time{
    position: absolute;
    bottom: 0;
    left: 0;
    color: rgba(0,0,0,.25);
  }
}

.carousel_content{
  position: absolute;
  bottom: 40px;
  left: 124px;
  .carousel_content-text {
    font-size: 32px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5000);
  }
  .carousel_content-bth {
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 32px;
    margin-top: 8px;
    cursor: pointer;
  }
  .icon-you{
    background: #DE1900;
    color: #fff;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
}
.first_content{
  margin-bottom: 48px;
}

.first_two_app_box{
  display: none;
  white-space: nowrap;
  padding-bottom: 8px !important;
  overflow-x: auto;
  .first_two_app{
    display: inline-block;
    padding: 4px 20px;
    background: #F4F6F9;
    border-radius: 16px;
    margin-right: 8px;
  }
  .first_two_app-change{
    color: #DE1900;
  }
}
.first_two_child-box{
  margin-top: 16px;
  display: none;
  .first_two_child{
    margin-top: 8px !important;
  }
}


</style>
