<template>
  <div>
    <el-breadcrumb 
      class="breadcrumb-class"
      separator="/">
      <el-breadcrumb-item 
        :to="{ path: '/CaseList',
        query: {
          title: '成功案例',
        }
        }">
        <!-- 成功案例 -->
        {{ $t('CaseList') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item> {{ caseName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="case-box">
      <div class="case-title">{{ caseName }}</div>
      <div class="case-img-box">
        <img :src="caseImg" alt="">
      </div>
      <div>

      </div>
      <div>
        <div 
          v-for="(item, index) in caseShowContent"
          :key="index"
          class="jour_three_item">
          <img class="jour_three_img" :src="item.image" alt=""/>
          <div class="jour_three_right">
            <div class="jour_three_title">{{ item.titile }}</div>
            <div v-if="item.pattern !== ''" class="jour_three_small">{{ $t('BiddingMode') }}：{{ item.pattern }}</div>
            <div v-if="item.area !== ''" class="jour_three_small">{{ $t('Area') }}：{{ item.area }}</div>
            <div
              class="jour_three_content">
              {{ $t('ProjectHighlights') }}：<br>
              <div class="keyPoints">
                {{ item.keyPoints }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 成功案例详情页面
export default {
  name: 'case-detail',
  components: {
  },
  data() {
    return {
      caseName: '',
      caseImg: '',
      index: this.$route.query.index,
      caseShowContent: []
    }
  },
  mounted () {
    // 添加对 sessionStorage 的监听
    this.caseList = JSON.parse(sessionStorage.getItem('caseList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    });
  },

  methods: {
    init () {
      let language = sessionStorage.getItem("language")
      this.itemShowList = []
      if (language === 'zh') {
        let caseContent = this.caseList[this.index]
        console.log(caseContent)
        this.caseName = caseContent.caseName // 案例名称
        this.caseImg = caseContent.casePicUrl // 案例图片
        this.caseShowContent = []
        caseContent.list.forEach((item) => {
          this.caseShowContent.push({
            titile: item.caseDetailTitle,
            pattern: item.caseDetailPattern,
            area: item.caseDetailArea ? item.caseDetailArea + 'm²' : '',
            image: item.caseDetailImage,
            keyPoints: item.keyPoints
          })
        })
      } else {
        let caseContent = this.caseList[this.index]
        console.log(caseContent)
        this.caseName = caseContent.caseNameEng // 案例名称
        this.caseImg = caseContent.casePicUrl // 案例图片
        this.caseShowContent = []
        caseContent.list.forEach((item) => {
          this.caseShowContent.push({
            titile: item.caseDetailTitleEng,
            pattern: item.caseDetailPatternEng,
            area: item.caseDetailArea ? item.caseDetailArea + 'm²' : '',
            image: item.caseDetailImage,
            keyPoints: item.keyPointsEng
          })
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-class{
  background: #fff;
  padding: 16px 32px;
  position: fixed;
  width: 100%;
  top: 64px;
  border-bottom: 1px solid #E8E8E8;
  z-index: 10;
  ::v-deep .el-breadcrumb__inner.is-link{
    color: #8B90A0 !important;
    font-weight: 500 !important;
    &:hover {
      color: #DE1900 !important;
    }
  }
  ::v-deep .el-breadcrumb__separator{
    font-weight: 500;
  }
  ::v-deep .el-breadcrumb__inner{
    color: #DE1900 !important;
  }
}

.case-box{
  margin: 106px 124px 60px;
}

.case-title{
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
}

.case-img-box{
  margin: 90px 56px 40px;
  img{
    width: 100%;
  }
}

.jour_three{
  margin-top: 32px;
  margin: 0 120px;
  .jour_three_item{
    &:nth-child(2) {
      padding-top: 0 !important;
    }
    &:last-child{
      border-bottom: none;
      padding-bottom: 0 !important;
    }
  }

}
.jour_three_item{
  padding: 24px 0;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .jour_three_img{
    object-fit: cover;
    width: 300px;
    height: 180px;
    border-radius: 4px;
    overflow: hidden;
  }
  .jour_three_right{
    margin-left: 20px;
    flex: 1;
    position: relative;
  }
  .jour_three_title{
    color: rgba(0,0,0,.85);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .jour_three_small{
    font-size: 14px;
    line-height: 24px;
    color: #666;
  }
  .jour_three_content {
    margin-top: 4px;
    font-size: 14px;
    line-height: 24px;
    color: #666;
  }
  .jour_three_time{
    position: absolute;
    bottom: 0;
    left: 0;
    color: rgba(0,0,0,.25);
  }
}

.keyPoints{
  white-space: pre-line;
}
</style>
