
// 英文
module.exports = {
    // 公共页面
    catalogue: 'Contents',
    CompanyInfo: 'About Us',
    CompanyIntro: 'Company Profile',
    CompanyCulture: 'Corporate Culture',
    ProductList: 'Products & Solution',
    
    JournalismList: 'News',
    JournalismList1: 'Company News',
    JournalismList2: 'Industry News',
    JournalismList3: 'Social Responsibility',
    JournalismList4: 'News Details',
    CaseList: 'Success Story',
    JoinUs: 'Join Us',
    RelationUs: 'Contact Us',

    QuickLinks: 'Quick Links',
    CustomerService: 'Customer Service',
    TheOfficialWeChat: 'The Official WeChat',
    Technology: 'Anhui Nodcare Medical Technology Co. Ltd',

    MoreAbout:  'More About',
    More: 'More',

    ReleaseTime: 'Release Time',

    WorkingLocation: 'Working Location',
    JobCategory: 'Job Category',
    JobTitle: 'Job Title',
    UpdateTime: 'Update Time',
    WorkingPlace: 'Working Place',

    BrandCulture: 'Brand Culture',
    BrandSpirit: 'Brand Spirit',
    BrandValues: 'Brand Values',
    BrandPhilosophy: 'Brand Philosophy',

    MoreCommitment: 'More Commitment',
    MoreAction: 'More Action',
    FormHealthy: 'Form Healthy',
    NodcareWelcome: 'Nodcare welcome to join us',

    productName1: 'Intelligent Medical Care',
    productName2: 'The Whole Solution Of Medical Purification',
    productName3: 'After-sale Management & Manintenang Of Medical Engineering',
    productName4: 'The Solution Of Environmental Protection System',
    productName5: 'Biopharmaceutical And Raw  Material Research And Development',

    noData: 'No Data',

    BrandIntroduction: 'With an emphasis on the research and development of smart medical care, medical purification systems, and hospital care systems, Anhui Nodcare Medical Technology Co., Ltd. was established in 2001.The primary line of business includes the provision of comprehensive solutions of hospital intelligence, informatization, and digitalization; research and development, design, implementation, operation and maintenance of hospital purification systems; and planning and design of the process, environmental protection, hospital infection management, environment, space, etc. of the medical care work area; the general setup service of the equipment. It is determined to create a modern, information-based, harmonious medical environment, add humanistic care, and interpret the era\'s concept of "focusing on health and caring for life".',

    BrandIntroduction1: 'After more than 20 years of growth, Nuodokang has created the perfect contemporary business structure. The company has a solid technical force, a large number of seasoned scientific researchers, and all-round and comprehensive cooperation with numerous renowned domestic universities and research institutes. It also established a joint research and development center and created a sustainable development path of  independent research and development, production-university-research integration, and benefit sharing, serving as a model for enterprises in the medical industry.',


    BrandCulture1Content: 'The brand culture is based on Nuodokang\'s development history, with a focus on the smart medical sector, enhancing corporate cohesion internally and expanding brand influence externally.',

    BrandCulture2: 'Brand Concept',
    BrandCulture2Small: 'Science and technology create beauty, and beauty is promised for thousands of families',
    BrandCulture2Content: 'By pushing the boundaries of cutting-edge technology and utilizing it to develop a more humane healthcare system, Nodcare makes access to medical experience more convenient for both patients and healthcare professionals and fulfils the ultimate promise of bringing beauty to thousands of families in the community.',

    BrandCulture3: 'Brand Vision',
    BrandCulture3Small: 'Leading the development of the industry through innovation, enabling every user to enjoy technological medical care',
    BrandCulture3Content: 'Nodcare firmly adheres to the China’s " development concepts of innovation, harmonization, green, openness and sharing", quickens the speed of innovation, pioneers the growth of the domestic smart medical system market, and fulfils the lovely goal of enabling every user to benefit from cutting-edge medical treatment.',

    BrandCulture4: 'Brand mission',
    BrandCulture4Small: 'Putting an emphasis on biopharmaceuticals plus smart medicine will improve human health.',
    BrandCulture4Content: 'The company also makes commitments to the public\'s health and uses its service to meet users\' expectations. In order to support the future growth of the medical business, Nodcare is dedicated to doing research on smart medical and biopharmaceuticals.  Attention is also paid to science and technology research, development, and innovation at the same time, and commitment is made to society to enable technology to enhance medical services and each person\'s quality of life.',

    Collaboration: 'Collaboration',
    CollaborationContent: 'To achieve competitive advantages and perform better than the average level of the same industry, the business increases their own technical innovation capabilities and R & D production efficiency.',

    pragmatic: 'Pragmatic',
    pragmaticContent:'The company realizes the internal coordination of module development and sincerely collaborate with partners to advance the accomplishment of thorough coordination and sustainable development.',

    HighEfficiency: 'High efficiency',
    HighEfficiencyContent: 'In terms of management, the company takes real actions, and all employees are hardworking and enterprising; in terms of behavior and thinking, the company always works steadily, pursues the truth, and consolidates the foundation of the enterprise.',

    flexible: 'Flexible',
    flexibleContent: 'Flexible and diversified management models give the company the opportunity to successfully respond to challenges and make progress while maintaining stability in the face of challenges brought on by unidentified market risks.',

    BrandValues1: 'The brand values of Nuodokang are deeply ingrained in the company and serve as its guiding principles in all business dealings. It is not only the concept and choice of enterprise leaders, but also the product of long-term accumulation in the development process of Nuodokang, which determines the development of Nuodokang\'s "foundation", "method" and "principle".',

    BrandValues2: 'Taking people as foundation',
    BrandValues2Content: 'People-orientedness is the fundamental commitment to brand behavior at Nuodokang, which is employee-centered internally and user-centered outside.',

    BrandValues3: 'Taking innovation as method',
    BrandValues3Content: 'Innovation helps firms swiftly enter the market and increase competitiveness by utilizing research and innovation to develop products and services that match consumer needs.',

    BrandValues4: 'Taking integrity as the principle',
    BrandValues4Content: 'Integrity provides Nuodokang\'s road direction indicator. Establishing the value concept and way of thinking that "integrity is the principle" is the foundation for the existence of the brand.',

    BrandPhilosophy1: 'Honesty and trustworthiness are necessary for stable development, win-win collaboration is necessary for a diverse development, and technological innovation is necessary for rapid development.',
    BrandPhilosophy11: 'Achieve steady development',
    BrandPhilosophy12: 'Achieve diversified development',
    BrandPhilosophy13: 'Drive rapid development',

    BrandPhilosophy2: 'Honesty',
    BrandPhilosophy2Content: 'Honesty and trustworthiness are essential components of doing business and impact how a firm is seen on the outside. To gain the trust of the public and promote steady growth, Nuodokang upholds honesty and trustworthiness as core values.',
    
    BrandPhilosophy3: 'Win-win cooperation',
    BrandPhilosophy3Content: 'Taking advantage of its distinctive advantages, Nodcare has steadily built up close cooperation relationships with a number of the nation\'s top hospitals such as Suzhou Depin, increasing its own chances and encouraging diverse development.',
    
    BrandPhilosophy4: 'Technological innovation',
    BrandPhilosophy4Content: 'In every era and stage, Nuodokang keeps coming up with new ideas, always has the most cutting-edge productivity tools, keeps up with the pace of the times, and promotes business growth.',

    // 医疗净化整体解决方案
    purificationTitle: 'Overall solution for medical purification',
    purificationLabel: 'Some representative achievements are as follows',
    BiddingMode: 'Bidding mode',
    BiddingMode1: 'professional contracting mode',
    Area: 'The construction area of the project is about',
    ProjectHighlights: 'Project Highlights',
    purificationAddr1: 'Hubei Province Northwest Hubei (Xiangyang) Major Epidemic Rescue Base ',
    purificationAddr2: 'Union Hospital Affiliated to Tongji Medical College, Huazhong University of Science and Technology',
    purificationAddr3: 'Yuhang District, The First Affiliated Hospital of Zhejiang University School of Medicine',

    ProjectHighlights11: 'The medical process and electromechanical major of the medical purification engineering department adopt the design concept of "transformation between the epidemic and normal model" to ensure the working environment of medical care;',
    ProjectHighlights12: 'The area is set aside for development, the medicinal gas is made to accommodate everyday use, and the "epidemic time" may be instantly transformed to accommodate the patients\' high-flow gas consumption;',
    ProjectHighlights13: 'here are many and advanced medical intelligent systems, creating a smart medical model project.',

    ProjectHighlights21: 'Large-scale first-class hospitals at grade 3 with influence in China and central China;',
    ProjectHighlights22: 'The project has undertaken hospital construction projects many times, and the construction quality and construction level have been highly recognized by the hospital;',
    ProjectHighlights23: 'The project won various engineering awards such as National Award (China Construction Engineering Decoration Award, Luban Award, etc.), Provincial Award, (Hubei Provincial High-quality Building Decoration Engineering Chutian Cup Award);',
    ProjectHighlights24: 'The project includes 17 digital operating rooms, which perfectly integrate medical treatment and high technology.',
    
    ProjectHighlights31: 'A well-known large-scale first class hospital at grade 3 with domestic influence;',
    ProjectHighlights32: 'Multi-department packaging, large volume;',
    ProjectHighlights33: 'The project includes 44 100-level blood wards;',
    ProjectHighlights34: 'The project includes the digitization of the Leonardo da Vinci operating room.',


    purificationTitle1: 'Overall solution of environmental care system',
    solution11: 'Nanjing Drum Tower Hospital (General Hospital)',
    solution12: 'Nanjing Drum Tower Hospital (Nanjing Drum Tower Hospital, the Affiliated Hospital to Nanjing University Medical School), built in 1892, is one of the country\'s first western hospitals. Drum Tower Hospital has experienced rapid progress in all areas of medicine, education, research, and management since the establishment of New China and the early stages of reform and opening-up. It has now grown into a large-scale, research-focused, comprehensive third-level class institution with all disciplines covered, an excellent faculty, cutting-edge medical equipment, and a strong foundation in scientific research.',
    solution21: 'The First Affiliated Hospital of Soochow University (General Hospital)',
    solution22: 'Soochow University\'s First Affiliated Hospital was established in 1883 (the ninth year of Guangxu in the Qing Dynasty). It is the first batch of first-class hospitals at grade 3 of the Ministry of Health, a provincial key hospital directly under the Jiangsu Provincial Health and Health Commission, and a medical guidance center in southern Jiangsu. The hospital has won National Advanced Group of Hospital Culture Construction, National Civilized Unit, and awards for hospital reform and innovation from the Health Report. It has consistently placed in the top 50 in the Fudan University Institute of Hospital Management\'s ranking of Chinese hospitals, has won the competition rating for Chinese prefecture-level urban hospitals for eight years running, and will be among the best hospitals in China in 2020, placing 32nd.',
    solution31: 'Longhua Hospital Affiliated to Shanghai University of Traditional Chinese Medicine (Traditional Chinese Medicine Hospital)',
    solution32: 'One of the four first TCM clinical bases in China was Longhua Hospital Affiliated to Shanghai University of Traditional Chinese Medicine, which was founded in July 1960. The hospital has been following the development plan of "renowned doctors, famous departments, famous hospitals, and famous medications" for more than 50 years. The hospital has chosen to concentrate on traditional Chinese medicine and to combine traditional Chinese and Western medicine. It has become a nationally famous Chinese medicine hospital, a national model hospital of Chinese medicine, first-class hospital at grade 3, and a national model hospital of traditional Chinese medicine, which integrates medical treatment, teaching and scientific research, with distinctive characteristics of traditional Chinese medicine and outstanding advantages of traditional Chinese medicine. The hospital took first place in the "Thirteen Consecutive Championships" of Shanghai Civilized Units; in 2007, it was designated as an advanced collective of the national health system. The National Development and Reform Commission and the State Administration of Traditional Chinese Medicine designated it as the first batch of construction units of the National Traditional Chinese Medicine Clinical Research Base in 2008. In 2012, it ranked first in the nation\'s third-level Chinese medicine hospitals in the evaluation results of the re-evaluation of Chinese medicine hospitals. In 2015, it was rated as the fourth National Civilized Unit. In 2016, it was rated as the fifth National Civilized Unit. Professor Liu Jiaxiang received the third "Master of Traditional Chinese Medicine" designation in June 2017. The hospital received its JCI (Joint Commission International) academic medical center status in June 2018, making it the first hospital in the world that specializes in Chinese medicine.',
    solution41: 'Zhejiang Provincial Hospital of Traditional Chinese Medicine (Traditional Chinese Medicine Hospital)',
    solution42: 'Hangzhou, China\'s version of heaven, is where Zhejiang Provincial Hospital of Traditional Chinese Medicine is situated. This place is in the shape of a pearl, surrounded by three rivers, adjacent to the East China Sea, and bordered by Tianmu. You can both enjoy the lovely waves of West Lake and the blue waves winding the Qiantang. Hangzhou is not only the birth place of Wu and Yue States, but also is the Southern Song Dynasty\'s imperial government\'s capital city. There is a dazzling array of pearls, jade and treasures on display in the market, and every household is full of silk and satin. Prosperity is unaffected by passing water. Both sides of the river have geling cinnabar, and the various delicious foods from Zhejiang and Hangzhou are beneficial to everyone. Numerous great physicians emerge, and their qualities and talents are fragrant and extensive. Orange and apricot complement each other, the shade is dense and fragrant. Doctors here are determined to save the world, and are always holy and selfless, and boast the spirit of self-sacrifice. Doctors of the place of Wu and Zhe are sincere, and their skills are inhabited by the common people, and their heart is benevolent, and it has been inherited for centuries.',
    solution51: 'Suzhou Guangji Hospital (specialty hospital)',
    solution52: 'As a mental first-class hospital at grade 3, Suzhou Guangji integrates prevention, teaching, treatment, and rehabilitation. 2015 saw the addition of the two brands Suzhou Mental Health Center and Suzhou Mental Health Center. In the Xiangcheng District of Suzhou City, the hospital is situated at No.11 Guangqian Road and No.14 Dazhuangli Road. The hospital has 1,200 beds, a building size of 156,000 square meters, a project cost of around 1 billion yuan, and a 128 mu land area. General psychiatry, geriatric psychiatry, paediatric psychiatry, clinical psychology, sleep disorders, rehabilitation, general internal medicine, psychiatric forensic identification, and stomatology are all available at the hospital after more than a century of ups and downs. It is the Suzhou mental illness clinical medical center of diagnosis and treatment facility for mental and psychological disorders.',
    solution61: 'West China Fourth Hospital of Sichuan University (specialized hospital)',
    solution62: 'Sichuan University\'s Fourth West China Hospital was established in 1976. It is a first-class hospital at grade 3 that operates under the National Health Commission and integrates medical care, instruction, scientific research, preventive, and rehabilitation. It is a Sichuan University-affiliated hospital that is directly under the Ministry of Education. A designated hospital for provincial and municipal social medical insurance, it is the only hospital that is integrated with the "School of Public Health" and offers a variety of specialty services, including geriatrics, palliative medicine, otolaryngology, and the prevention and control of occupational diseases. It also provides services for the benefit of society and the general public as the Sichuan Occupational Disease Prevention and Control Institute, Chengdu Occupational Hygiene and Occupational Disease Special Committee, Chairman Unit of Sichuan Provincial, Poisoning Treatment Base, Chengdu Occupational Poisoning and Nuclear Radiation Injury Treatment Base, Sichuan Chemical Poisoning Treatment Drug Repository, Sichuan Occupational Disease Prevention and Control Base, and others.',
    solution71: 'Nanjing Second Hospital (Disease Control Hospital)',
    solution72: 'Nanjing Hospital Affiliated to Nanjing University of Traditional Chinese Medicine, Nanjing Second Clinical College of Xuzhou Medical University, Second Affiliated Hospital of Southeast University, Nanjing Cancer Hospital are additional names for Nanjing Second Hospital (Jiangsu Infectious Disease Hospital, Nanjing Public Health Medical Center). It is a first-class hospital at grade 3 infectious disease hospital that blends medical care, teaching, scientific research, and prevention. The Soong Mei Ling Foundation founded it in 1933. The hospital has won numerous awards over the years, including those for "National Health System Advanced Group for Fighting SARS," "National Advanced Grass-roots Party Organization for SARS Prevention and Control," "Outstanding Unit in Human Infection Prevention and Control of H7N9 Avian Influenza Epidemic," and "National Ebola Hemorrhagic Fever Epidemic Prevention and Control." "Advanced Collective for Controlling the New Coronary Pneumonia Epidemic", "National Advanced Collective for Fighting the New Coronary Pneumonia Epidemic", "National Advanced Grassroots Party Organization" and other honorary titles.',
    solution81: 'Shanghai Jiaotong School of Medicine University (Disease Control Hospital)',
    solution82: 'The Shanghai Municipal People\'s Government, the World Health Foundation (Project HOPE), and other children\'s hospitals collaborated to establish the Shanghai Children\'s Medical Center, a first-class hospital at grade 3 affiliated with Shanghai Jiao Tong University School of Medicine (referred to as the "Children\'s Center" for short). On June 1, 1998, the hospital was declared to be operational. Mrs. Hillary Clinton, the spouse of the former US President, cut the ribbon to officially open the hospital. The Pediatric Center was commended as "a paradigm of effective Sino-US medical cooperation" by Mr. Locke, the newly appointed US ambassador to China, who toured the facility in 2011.',
    solution91: 'Huadong Sanatorium',
    solution92: 'Chen Yi, the first mayor of Shanghai, constructed the Huadong Sanatorium and it opened its doors in 1951. It is run by the Shanghai Municipal Health Bureau and is the only sanatorium in Shanghai for cadres. On Daji Mountain, part of Wuxi\'s well-known Taihu Lake Scenic Area, is where Huadong Sanatorium is situated. One of Taihu Lake\'s 72 peaks is Daji Mountain. It has a surface area of 210,000 square meters and is 34 meters above sea level. The "Cape of Good Hope" in the southwest corner stretches into the lake, the hills are lined with green trees, the mountains are stacked, and the air is clean and replete with negative ions. The buildings in the courtyard are dispersed, concealed in the greenery and flowers, partly hidden and partly visible. The ideal burial site and the surrounding natural surroundings are beneficial for the body\'s recuperation. It is a unique destination for tourism, healing, and recovery. A tertiary specialty hospital integrating convalescence and rehabilitation, Huadong Sanatorium serves as the primary medical facility for Shanghai bureau-level cadres. In addition to the more than 300 workers, there are 230 medical technicians and more than 110 of them have won the senior and intermediate professional titles. Patients with diverse geriatric diseases, chronic illnesses, and post-operative convalescence are their primary focus. There are over 200 special examination items in addition to over 100 physical examination items. Convalescents are given health supervision and health management, which are very popular, through tailored health consultation, expert health care classes, and follow-up of exceptional cases. There are 19 rehabilitation facilities with a total of 499 beds, some mid- to high-end suites and villas, and round-the-clock high-quality services.',

    dizhiImg: require('@/assets/images/dizhi-en.jpg') 
}









