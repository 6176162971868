<template>
  <div class="jour_box">
    <div class="jour_title">
      <!-- 公司新闻 -->
      {{ $t('JournalismList') }}
    </div>
    <div class="jour_three">
      <!-- 企业新闻 -->
      <DetailFrame
        id="jour1"
        :CulTitle="$t('JournalismList1')">
        <template
          v-if="newsList1.length"
          slot="content">
          <el-carousel 
            height="400px">
            <el-carousel-item v-for="item in newsList1" :key="item">
              <div @click="skipPage('企业新闻', 1, item)">
                <img class="carousel_img" :src="item.img" alt="">
                <!-- 新闻标题 -->
                <div class="carousel_content">
                  <div class="carousel_content-text">{{ item.newsName }}</div>
                  <div class="carousel_content-bth">
                    <!-- 更多 -->
                    {{ $t('More') }}
                    <i class="icon-you iconfont"></i>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
        <template
          v-else
          slot="content">
          <DefaultImg/>
        </template>
      </DetailFrame>

      <!-- 行业动态 -->
      <DetailFrame
        id="jour2"
        :CulTitle="$t('JournalismList2')">
        <template
          v-if="newsList2.length"
          slot="content">
          <!-- <div class="icon-you-text">
            {{ $t('More') }}
            <i class="iconfont icon-you"/>
          </div> -->
          <div 
            v-for="(item, index) in newsList2"
            :key="index"
            class="jour_three_item"
            @click="skipPage('行业动态', 2, item)">
            <img class="jour_three_img" :src="item.img" alt=""/>
            <div class="jour_three_right">
              <div class="jour_three_title">{{ item.newsName }}</div>
              <div 
                v-if="item.newsKeyword && item.newsKeyword !== ''"
                class="jour_three_small">
                <div 
                  v-for="(item1, index1) in item.newsKeyword.split(',')"
                  :key="index * 10 + index1">
                  {{ item1 }}
                </div>
              </div>
              <div class="jour_three_content text_hiddle_4">{{ item.newIntroduction }}</div>
              <div class="jour_three_time">{{ parseTime(item.createTime) }}</div>
            </div>
          </div>
        </template>
        <template
          v-else
          slot="content">
          <DefaultImg/>
        </template>
      </DetailFrame>

      <!-- 媒体聚焦 -->
      <DetailFrame
        id="jour3"
        :CulTitle="$t('JournalismList3')">
         <template
          v-if="newsList3.length"
          slot="content">
          <div 
            v-for="(item, index) in newsList3"
            :key="index"
            @click="skipPage('媒体聚焦', 3, item)"
            class="jour_four_item">
            <img class="jour_four_img" :src="item.img" alt=""/>
            <div class="jour_four_right">
              <div class="jour_four_title text_hiddle_1">{{ item.newsName }}</div>
              <div class="jour_four_content text_hiddle_2">{{ item.newIntroduction }}</div>
              <div class="jour_four_time">{{ parseTime(item.createTime) }}</div>
            </div>
          </div>
        </template>
        <template
          v-else
          slot="content">
          <DefaultImg/>
        </template>
      </DetailFrame>
    </div>
  </div>
</template>

<script>
import DetailFrame from '@/components/detail-frame.vue'
import DefaultImg from '@/components/default-img.vue'
// 新聞列表
export default {
  name: 'jour-list',
  components: {
    DetailFrame,
    DefaultImg
  },
  data() {
     return {
      bannarList: [{
        url: require('@/assets/images/bannar1.png')
      }, {
        url: require('@/assets/images/bannar2.png')
      }, {
        url: require('@/assets/images/bannar3.png')
      }, {
        url: require('@/assets/images/bannar4.png')
      }],
      newContentList: [{},{}],

      newsList: [], // 所有新闻列表
      newsList1: [],
      newsList2: [],
      newsList3: []
    }
  },

  mounted () {
    // 添加对 sessionStorage 的监听
    
    this.newsList = JSON.parse(sessionStorage.getItem('newsList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    });

  },

  watch: {
    $route: {
      deep: true,
      immediate:true,
      handler (newData) {
        if (newData && newData.query.jourId !== undefined) {
          console.log(newData.query.jourId)
            this.$nextTick(() => {
              document.querySelector("#jour" + newData.query.jourId).scrollIntoView(true)
            })
        }
      }
    }
  },

  methods: {
    // 跳转详情页面
    skipPage (jourName, jourId, item) {
      this.$router.push({
        name: 'JournalismDetail',
        query: {
          jourName: jourName,
          jourId: jourId,
          newsId: item.newsId
        }
      })
    },

    ImgEvent (content) {
      const regex = /<img[^<]*>/g
      const regex1 = /http[^"]*"/g
      let list = []
      let list1 = []
      let returnContent = ''
      list = content.match(regex) || []
      if (list && list.length) {
        list1 = list[0].match(regex1)
      }
      if (list1 && list1.length) {
        returnContent = list1[0].replace('"', '')
      }
      

      console.log('listlist', returnContent)
      return returnContent !== '' ? returnContent : require('@/assets/images/bannar4.png')
    },

    // 时间戳转为正常时间的公共方法，当然你也可以加上小时、分、秒
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },

    init () {
      // 新闻模块处理
      this.newsList1 = []
      this.newsList2 = []
      this.newsList3 = []


      // 产品模块处理
      let language = sessionStorage.getItem("language")
      if (language === 'zh') {
        this.newsList.forEach(item => {
          if (item.newsType === '企业新闻') {
            this.newsList1.push({
              newsId: item.newsId,
              newsName: item.newsName,
              newIntroduction: item.newIntroduction,
              createTime: item.createTime,
              newsKeyword: item.newsKeyword,
              img: this.ImgEvent(item.newRichStr)
            })
          }
          if (item.newsType === '行业动态') {
            this.newsList2.push({
              newsId: item.newsId,
              newsName: item.newsName,
              newIntroduction: item.newIntroduction,
              createTime: item.createTime,
              newsKeyword: item.newsKeyword,
              img: this.ImgEvent(item.newRichStr)
            })
          }
          if (item.newsType === '媒体聚焦') {
            this.newsList3.push({
              newsId: item.newsId,
              newsName: item.newsName,
              newIntroduction: item.newIntroduction,
              createTime: item.createTime,
              newsKeyword: item.newsKeyword,
              img: this.ImgEvent(item.newRichStr)
            })
          }
        })
      } else {
        this.newsList.forEach(item => {
          if (item.newsType === '企业新闻') {
            this.newsList1.push({
              newsId: item.newsId,
              newsName: item.newsNameEng,
              newIntroduction: item.newIntroductionEng,
              createTime: item.createTime,
              newsKeyword: item.newsKeywordEng,
              img: this.ImgEvent(item.newRichEngStr)
            })
          }
          if (item.newsType === '行业动态') {
            this.newsList2.push({
              newsId: item.newsId,
              newsName: item.newsNameEng,
              newIntroduction: item.newIntroductionEng,
              createTime: item.createTime,
              newsKeyword: item.newsKeywordEng,
              img: this.ImgEvent(item.newRichEngStr)
            })
          }
          if (item.newsType === '媒体聚焦') {
            this.newsList3.push({
              newsId: item.newsId,
              newsName: item.newsNameEng,
              newIntroduction: item.newIntroductionEng,
              createTime: item.createTime,
              newsKeyword: item.newsKeywordEng,
              img: this.ImgEvent(item.newRichEngStr)
            })
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.jour_title {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  text-align: center;
  margin-top: 60px;

  &::before {
    content: '';
    display: inline-block;
    width: 200px;
    height: 6px;
    margin-right: 40px;
    position: relative;
    top: -8px;
    background: url(@/assets/images/title-left.png);
  }

  &::after {
    content: '';
    display: inline-block;
    width: 200px;
    height: 6px;
    position: relative;
    top: -8px;
    margin-left: 40px;
    background-size: 100% 100%;
    background: url(@/assets/images/title-right.png);
  }
}


.carousel_img{
  height: 400px;
  width: 100%;
  background: #ccc;
  border: none !important;
  object-fit: cover;
  cursor: pointer;
}
.jour_mainTitle{
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  margin-top: 60px;
  text-align: center;
}
.jour_smallTitle{
  font-size: 14px;
  font-weight: 400;
  color: #DE1900;
  line-height: 22px;
  text-align: center;
  margin-top: 12px;
}
.jour_one{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.jour_one_img{
  width: 500px;
  height: 270px;
  background: #368fff;
}
.jour_one_text{
  padding: 36px 20px 20px;
  background: #F9FAFC;
  position: relative;
}
.jour_one_text1{
  font-size: 16px;
  color: #333;
  font-weight: 500px;
  font-weight: bold;
}
.jour_one_text2{
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-top: 16px;
  padding-right: 100px;
  text-align: justify;
}
.jour_one_text3 {
  color: #DE1900;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  right: 120px;
  cursor: pointer;
}
.jour_two{
  background: #F9FAFC;
  height: 240px;
  padding: 0 120px;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  &:hover {
    .jour_two_item-change {
      width: 170px;
      background: rgba(255,255,255,0.6800);
      .jour_two_name{
        color: #333;
        white-space: pre-line;
      }
      .jour_two_EleName{
        color: #8B90A0;
        white-space: pre-line;
      }
    }
  }
}

.jour_two_item {
  width: 170px;
  background: rgba(255,255,255,0.6800);
  backdrop-filter: blur(9px);
  border-right: 1px solid #f2f2f2;
  padding: 20px;
  cursor: pointer;
  transition-duration: .3s;
  .jour_two_name{
    font-size: 16px;
    color: #333;
    line-height: 24px;
    white-space: pre-line;
  }
  .jour_two_EleName{
    font-size: 12px;
    color: #8B90A0;
    line-height: 22px;
    white-space: pre-line;
    margin-top: 8px;
  }
  &:hover {
    width: 300px !important;
    background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%) !important;
    .jour_two_name{
      color: #fff !important;
      white-space: nowrap !important;
    }
    .jour_two_EleName{
      color: #fff !important;
      white-space: initial !important;
    }
  }
}
.jour_two_item-change{
  width: 300px;
  background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%);
  .jour_two_name{
    color: #fff;
    white-space: nowrap;
  }
  .jour_two_EleName{
    color: #fff;
    white-space: initial;
  }
}
.jour_three{
  margin-top: 32px;
  margin: 0 120px;
  .jour_three_item{
    &:nth-child(2) {
      padding-top: 0 !important;
    }
    &:last-child{
      border-bottom: none;
      padding-bottom: 0 !important;
    }
  }

}
.jour_three_item{
  padding: 24px 0;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .jour_three_img{
    object-fit: cover;
    width: 300px;
    height: 180px;
    border-radius: 4px;
    overflow: hidden;
  }
  .jour_three_right{
    margin-left: 20px;
    flex: 1;
    position: relative;
  }
  .jour_three_title{
    color: rgba(0,0,0,.85);
    font-size: 16px;
    font-weight: bold;
  }
  .jour_three_small{
    margin-top: 16px;
    div{
      display: inline-block;
      font-size: 12px;
      margin-right: 8px;
      padding: 0 8px;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      color: #DE1900;
      border: 1px solid #DE1900;
    }
  }
  .jour_three_content {
    margin-top: 14px;
    color: rgba(0,0,0,.65);
  }
  .jour_three_time{
    position: absolute;
    bottom: 0;
    left: 0;
    color: rgba(0,0,0,.25);
  }
}

.jour_four_item{
  width: calc(50% - 20px);
  display: inline-block;
  background: #fff;
  margin-right: 40px;
  margin-bottom:  24px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  .jour_four_img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
  }
  .jour_four_right{
    padding: 16px 40px;
  }
  .jour_four_title{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
  .jour_four_content{
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.6500);
    line-height: 24px;
    margin-top: 16px;
  }
  .jour_four_time{
    // width: 113px;
    height: 22px;
    font-size: 14px;
    color: rgba(0,0,0,0.2500);
    line-height: 22px;
    margin-top: 16px;
  }
  &:nth-child(2n + 1) {
    margin-right: 0;
  }
  &:hover {
    box-shadow: 4px 6px 20px -8px rgba(0,0,0,0.0800);
  }
}

#jour2 {
  position: relative;
  .icon-you-text {
    position: absolute;
    top: 12px;
    right: 0;
    color: #DE1900;
    cursor: pointer;
  }
  .icon-you{
    font-size: 12px;
    position: relative;
    top: -1px;
  }
}

#jour3 {
  margin-bottom: 60px;
}

.carousel_content{
  position: absolute;
  top: 180px;
  left: 60px;
  .carousel_content-text {
    font-size: 32px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5000);
  }
  .carousel_content-bth {
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 32px;
    margin-top: 8px;
    cursor: pointer;
  }
  .icon-you{
    background: #DE1900;
    color: #fff;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
}
</style>
