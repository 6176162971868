<template>
  <div id="app">
    <CommonPage :class="{'app-change': appChange }"/>
  </div>
</template>

<script>
import CommonPage from './view/common-page.vue'
export default {
  name: 'App',
  components: {
    CommonPage
  },
  data () {
    return {
      screenWidth: 0,
      appChange: false
    }
  },
  mounted () {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      console.log('高度');
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 800) {
        console.log(n)
        sessionStorage.setItem('appChange', true)
        this.appChange = true
      } else {
        console.log(n)
        sessionStorage.setItem('appChange', false)
        this.appChange  = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/common/scss/app-scss.scss'

</style>
<style>
*{
  margin: 0;
  padding: 0;
}

.text_hiddle_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text_hiddle_2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_hiddle_4{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text_hiddle_6{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.text_hiddle_11{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
