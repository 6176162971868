/* 用户相关api */
export default {
    API_LIST_USER: {
      // 登录
      productList: '/system/product/web/list',
      // 查询管理员列表
      INTERFACE_GET_USER_LIST: 'admin/users',
      // 新增管理员
      INTERFACE_POST_USER_ADMIN: 'admin/register',
      // 删除管理员
      INTERFACE_DELETE_USER_ADMIN: 'admin/users',
    },
  };    