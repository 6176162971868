// 中文
module.exports = {
    // 公共页面
    catalogue: '目录',
    CompanyInfo: '关于诺多康',
    CompanyIntro: '公司简介',
    CompanyCulture: '企业文化',
    ProductList: '产品及解决方案',

    JournalismList: '新闻信息',
    JournalismList1: '公司新闻',
    JournalismList2: '行业新闻',
    JournalismList3: '社会责任',
    JournalismList4: '新闻详情',

    CaseList: '成功案例',
    JoinUs: '加入我们',
    RelationUs: '联系我们',

    QuickLinks: '快速链接',
    CustomerService: '客服电话',
    TheOfficialWeChat: '官方微信',
    Technology: '安徽诺多康医疗科技有限公司',

    MoreAbout:  '了解更多',
    More: '更多',

    ReleaseTime: '发布时间',

    WorkingLocation: '工作地点',
    JobCategory: '职位类别',
    JobTitle: '职位名称',
    UpdateTime: '更新时间',
    WorkingPlace: '工作地点',

    BrandCulture: '品牌文化',
    BrandSpirit: '品牌精神',
    BrandValues: '品牌价值观',
    BrandPhilosophy: '品牌哲学',

    MoreCommitment: '诺于言',
    MoreAction: '多于行',
    FormHealthy: '始于康',
    NodcareWelcome: '诺多康 期待您的加入',

    noData: '暂无数据',

    BrandIntroduction: '安徽诺多康医疗科技有限公司创建于 2001 年，专注于智慧医疗、医疗净化系统、医院院感护理系统的研究与开发。主营业务包括医院智能化、信息化、数字化整体解决方案的提供；医院净化系统研发、设计、实施和运维；医疗护理工作区域的流程、环保、院感、环境、空间等方面的规划设计以及设备整体配置服务，营造现代化、信息化的和谐医疗环境，注入人文关怀，演绎“关注健康关爱生命”的时代主题。',

    BrandIntroduction1: '经过二十多年的发展，诺多康已经建立起完善的现代企业架构。公司技术力量雄厚，拥有众多经验丰富的科研人员，并与国内多家知名高校、科研院所建立了全方位、立体化的合作，成立了联合研发中心，走出了一条自主研发、产学研一体、利益共享的可持续发展道路，为行业企业树立了典范。',

    BrandCulture1Content: '品牌文化立足于诺多康发展历程，着眼于智慧医疗行业，对内增强企业凝聚力、对外扩大品牌影响力。',

    BrandCulture2: '品牌理念',
    BrandCulture2Small: '科技创造美好，美好承诺万家',
    BrandCulture2Content: '诺多康突破前沿技术，用科技创造更加人性化的医疗体系，为患者及医护工作者带来轻松医疗体验，实现美好予以社会千万家的最终承诺。',

    BrandCulture3: '品牌愿景',
    BrandCulture3Small: '通过创新引领行业发展，让每位用户享受科技医疗',
    BrandCulture3Content: '诺多康紧紧围绕国家“五大发展理念”，加快创新步伐，引 领国内智慧医疗系统行业发展，实现让每位用户享受科技医 疗的美好愿景。',

    BrandCulture4: '品牌使命',
    BrandCulture4Small: '聚焦智慧医疗+生物制药,科技创新让生命更健康',
    BrandCulture4Content: '诺多康企业为大众健康承诺，以服务满足用户期待，致力于智慧医疗及生物制药的研究，为未来医疗行业发展赋能。同时关注科技研发与技术创新，向社会承诺让科技优化医疗服务、让科技提高每个生命的健康值。',

    Collaboration: '协同',
    CollaborationContent: '企业需要提高自身科技创新能力和研发生产效率，从而获得竞争优势、领先于同行业平均水平。',

    pragmatic: '务实',
    pragmaticContent:'对内实现模块协同发展对外与合作伙伴精诚合作，推动企业实现全面协调可持续发展。',

    HighEfficiency: '高效',
    HighEfficiencyContent: '管理上真抓实干、全体员工拼搏进取；行为思想上踏实稳干，追求真理，夯实企业立足之基。',

    flexible: '灵活',
    flexibleContent: '面对未知市场风险带来的挑战，灵活多样的管理模式使企业能有效应对，在挑战中稳中求进。',
    
    BrandValues1: '诺多康品牌价值观根植于企业内部，是引领企业进行一切经营活动的指导性原则。它不仅企业领导者的观念与选择，更是诺多康发展历程中长期积淀的产物，决定着诺多康“本”“术”“道”的发展。',

    BrandValues2: '以人为本',
    BrandValues2Content: '诺多康对外以用户为中心，对内以员工为中心的发展思想，以人为本是品牌行为的基本遵循。',

    BrandValues3: '创新为术',
    BrandValues3Content: '通过探索创新，打造满足用户需求的产品及服务，创新帮助品牌快速占领市场，提升竞争力。',

    BrandValues4: '诚信为道',
    BrandValues4Content: '诚信作为诺多康的道路方向标，建立“诚信为道”的价值观念和思维方式，是品牌存在基础。',

    BrandPhilosophy1: '诚实守信，实现稳定发展；合作共赢，实现多元发展；科技创新，驱动快速发展。',
    BrandPhilosophy11: '实现稳定发展',
    BrandPhilosophy12: '实现多元发展',
    BrandPhilosophy13: '驱动快速发展',

    BrandPhilosophy2: '诚实守信',
    BrandPhilosophy2Content: '诚实守信是经商的根本，参与决定企业外界形象诺多康坚持以诚实守信作为经营哲学，赢得社会信赖，实现稳定发展。',
    
    BrandPhilosophy3: '合作共赢',
    BrandPhilosophy3Content: '诺多康以自身独特优势，先后与全国众多知名医院、医疗机构建立深度合作关系，增大自身机遇机会，推动多元化发展。',
    
    BrandPhilosophy4: '科技创新',
    BrandPhilosophy4Content: '诺多康在每个时期、阶段都持续进行创新，始终保持企业生产力，科技先进性，跟随时代前进脚步，驱动企业发展。',


    // 医疗净化整体解决方案
    purificationTitle: '医疗净化整体解决方案',
    purificationLabel: '部分代表业绩如下',
    BiddingMode: '招标模式',
    BiddingMode1: '专业承包模式',
    Area: '项目建设面积约为',
    ProjectHighlights: '项目亮点',
    purificationAddr1: '湖北省鄂西北(襄阳)重大疫情救治基地',
    purificationAddr2: '华中科技大学同济医学院附属协和医院',
    purificationAddr3: '浙江大学医学院附属第一医院余杭院区 ',

    ProjectHighlights11: '医疗净化工程科室医疗流程与机电专业采用“平疫转换”设计理念，保障医护工作环境;',
    ProjectHighlights12: '医用气体设计满足平时使用，并预留可发展用地，“疫时”可迅速转换，满足疫时病患高流量用气;',
    ProjectHighlights13: '医疗智能化系统多且先进，打造智慧化医疗样板项目。',

    ProjectHighlights21: '国内及华中地区具有影响力的大型三甲医院;',
    ProjectHighlights22: '多次承接医院施工项目，施工质量及施工水平得到院方高度认可;',
    ProjectHighlights23: '荣获国奖(中国建筑工程装饰奖、鲁班奖等)、省奖、(湖北省优质建筑装饰工程楚天杯奖)等多种工程奖项;',
    ProjectHighlights24: '项目包含17间数字化手术室，将医疗与高科技完美融合。',
    
    ProjectHighlights31: '国内具有影响力的知名大型三甲医院;',
    ProjectHighlights32: '多科室打包，体量大;',
    ProjectHighlights33: '项目包含44间百级血液病房;',
    ProjectHighlights34: '项目包含数字化达芬奇手术室。',


    purificationTitle1: '环保护理系统整体解决方案',
    solution11: '南京鼓楼医院(综合医院)',
    solution12: '南京鼓楼医院（南京大学医学院附属鼓楼医院），建于1892年，为中国最早的西医院之一。自新中国成立到改革开放初期，鼓楼医院医教研管各方面取得快速发展，目前已发展成为学科门类齐全、师资力量雄厚、医疗技术精湛、科研实力较强的大型研究型综合性三级甲等医院。',
    solution21: '苏州大学附属第一医院(综合医院)',
    solution22: '苏州大学附属第一医院始创于1883年（清光绪九年），是卫生部首批三级甲等医院，为江苏省卫健委直属的省级重点医院，苏南地区医疗指导中心。医院先后荣获全国文明单位、全国医院文化建设先进集体、健康报医院改革创新奖等称号。在复旦大学医院管理研究所发布的中国医院排行榜中，连续多年进入前50强，连续八年在中国地级城市医院竞争力排行榜中位列第一，并在2020年中国顶级医院排行榜中名列第32位。',
    solution31: '上海中医药大学附属龙华医院(中医医院)',
    solution32: '上海中医药大学附属龙华医院创建于1960年7月，是全国最早建立的四大中医临床基地之一。50余年来，医院坚持“名医、名科、名院、名药”的发展战略，走中医为主、中西医结合的道路，已成为集医疗、教学、科研为一体，中医特色鲜明和中医优势突出的全国著名中医医院、全国示范中医院、上海市三级甲等医院。医院获上海市文明单位“十三连冠”;2007年被评为全国卫生系统先进集体;2008年被国家发改委、国家中医药管理局首批确定为国家中医临床研究基地建设单位;2009年成为国家教育部长江学者奖励计划特聘教授设岗单位;2012年在中医院等级复评审中评审成绩位列全国三级中医医院首位;2015年被评为第四届全国文明单位;2016年荣获上海市五一劳动奖状;2017年5月被国家中医药管理局正式确定为国家中医临床研究基地。2017年6月刘嘉湘教授荣膺第三届“国医大师”称号。2018年6月医院通过JCI(Joint Commission International)初次评审，成为全球首家通过JCI学术型医学中心认证的中医医院。',
    solution41: '浙江省中医院(中医医院)',
    solution42: '浙江省中医院位于华夏之东南，人间之天堂——杭州，此地明珠形胜，三江襟纳，东海毗邻，天目接壤。蜿钱塘而翻碧波，环西子而腾细浪。吴越故里，南宋皇衙，流年更添人家；户盈罗绮，市列珠玑，逝水不洗繁华。葛岭丹砂，泽被之江两岸；浙杭八味，惠及四海天涯。大医辈出，德艺馨而远扬；橘杏交晖，林荫密而飘香。往圣无私，殚精济世；先贤有情，割股寿人。吴浙精诚，术寓苍生；医心仁和，百世传承。',
    solution51: '苏州市广济医院(专科医院)',
    solution52: '苏州市广济医院是一家集治疗、康复、防治、教学为一体的三级甲等精神病专科医院。2015年增挂苏州市精神卫生中心、苏州市心理卫生中心两块牌子。医院位于苏州市相城区广前路11号、大庄里路14号。医院占地128亩，项目投资约10亿元，建筑面积15.6万平方米，编制床位1200张。医院历经百余年风风雨雨，设有普通精神科、老年精神科、儿少精神科、临床心理科、睡眠障碍科、康复科、综合内科、精神病司法鉴定科、口腔科。是精神及心理障碍诊疗中心，是苏州市精神疾病临床医学中心。',
    solution61: '四川大学华西第四医院(专科医院)',
    solution62: '四川大学华西第四医院始建于1976年，是直属国家卫生健康委员会的集医疗、教学、科研，预防和康复于一体的三级甲等医院；是教育部直属四川大学的附属医院，是全国唯一与“公共卫生学院”合为一体的医院；是省、市社会医疗保险定点医院，拥有老年科、姑息医学、耳鼻喉科和职业病防治等一批特色专科，同时作为四川省和成都市化学中毒救治基地，成都市职业中毒与核辐射损伤救治基地，四川省化学中毒救治药品储备库，四川省职业病防治院，四川省、成都市劳动卫生与职业病专委会主任委员单位和国家卫生健康委职业病医师培训基地等服务于社会和公众。',
    solution71: '南京市第二医院(疾控类医院)',
    solution72: '南京市第二医院(江苏省传染病医院，南京市公共卫生医疗中心），又名南京中医药大学附属南京医院，徐州医科大学南京第二临床学院，东南大学附属第二医院，南京市肿瘤医院。由宋美龄基金会创建于1933年，是集医疗、教学、科研、预防为一体，以“精专科、强综合、重防治、应突发”为特色的三级甲等传染病医院。医院先后荣获“全国卫生系统抗击非典先进集体”、“全国防治非典型肺炎工作先进基层党组织”、“人感染H7N9禽流感疫情防治工作中表现突出单位”、“国家埃博拉出血热疫情防控先进集体”、“全国抗击新冠肺炎疫情先进集体”、“全国先进基层党组织”等荣誉称号。',
    solution81: '上海交通大学医学院(疾控类医院)',
    solution82: '上海交通大学医学院附属上海儿童医学中心（简称“儿中心”）是由上海市人民政府与世界健康基金会（Project HOPE）合作共建的一所集医、教、研于一体的三级甲等儿童专科医院。1998年6月1日医院正式开张，美国前总统夫人希拉里 · 克林顿女士为医院开张剪彩，2011年，美国新任驻华大使骆家辉先生到访医院，将儿中心誉为“中美医学成功合作典范”。',
    solution91: '华东疗养院',
    solution92: '华东疗养院创建于1951年，由上海市第一任市长陈毅同志亲自选址建造，是上海市唯一的一所干部疗养院，隶属于上海市卫生局领导。华东疗养院座落在无锡著名的太湖风景区大箕山上，大箕山，太湖七十二峰之一，海拔34米，占地21万平方米，与鼋头渚、太湖仙岛呈成三足鼎立之态。山上绿树成荫，层峦叠嶂，空气清新，饱含负离子；西南角的"；好望角"；更逶迤伸至水中；院区建筑错落有致，掩映于绿树鲜花之中，若隐若现。优越的地埋位置和自然生态环境，对人体具有良好的康复作用，是一处不可多得的疗养康复、旅游度假胜地。华东疗养院是一所集疗养与康复为一体的三级专科医院，是上海市局处级干部的保健基地。现有职工300余人，其中医技人员230人，高、中级职称110余人，主要收治各种老年病、慢性病和术后恢复期患者医疗康复，接待健康体检以及外宾的保健疗养，开展常规疗养、体检项目百余项，另设特殊检查200余项，通过个性化健康咨询、专家保健课、特殊病例的追踪随访对疗养员进行健康指导、健康管理，广受欢迎。现有疗养楼19幢，499张床位，并提供部分中高档套房和别墅房，全天候提供优质服务。',

    dizhiImg: require('@/assets/images/dizhi-zh.png') 
}

