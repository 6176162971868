import { render, staticRenderFns } from "./relation-us.vue?vue&type=template&id=1944fc62&scoped=true&"
import script from "./relation-us.vue?vue&type=script&lang=js&"
export * from "./relation-us.vue?vue&type=script&lang=js&"
import style0 from "./relation-us.vue?vue&type=style&index=0&id=1944fc62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1944fc62",
  null
  
)

export default component.exports