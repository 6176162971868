<template>
  <div class="company-box company-intro">
    <div class="company-item">
      <div class="company-title">
        <div class="company-title-content">{{ productName }}</div>
        </div>

      <div class="company-content">
        <div 
          v-for="(item, index) in companyShowList"
          :key="index">
          <DetailFrame
            :CulTitle="item.name"
            :CulImgList="item.img"
            :CulContent="item.content"
            :CulIconList="item.CulIconList"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// 产品详情
import DetailFrame from '@/components/detail-frame.vue'
export default {
  name: 'product-detail',
  components: {
    DetailFrame
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler (newData) {
        if (newData) {
          this.index = newData.query.index
          this.init()
        }
      }
    }
  },
  data() {
    return {
      index: this.$route.query.index,
      productName: '',
      companyShowList: []
    }
  },

  mounted () {
    // 添加对 sessionStorage 的监听
    this.productList = JSON.parse(sessionStorage.getItem('productList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    })
  },

  methods: {
    init () {
      let language = sessionStorage.getItem("language")
      this.companyShowList = []
      if (language === 'zh') {
        this.productName = this.productList[this.index].productName // 系列名称

        this.productList[this.index].list.forEach(item => {
          this.companyShowList.push({
            name: item.detailTitle,
            content: item.detailDesc,
            img: item.picList
          })
        })
      } else {
        this.productName = this.productList[this.index].productNameEng // 系列名称

        this.productList[this.index].list.forEach(item => {
          this.companyShowList.push({
            name: item.detailTitleEng,
            content: item.detailDescEng,
            img: item.picList
          })
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.company-box{
  .company-item{
    margin-top: 60px;
  }
  .company-title {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    text-align: center;

    &::before {
      content: '';
      display: inline-block;
      width: 200px;
      height: 2px;
      margin-right: 40px;
      position: relative;
      top: -8px;
      background: linear-gradient(90deg, rgba(236,125,111,0) 0%, rgba(222,25,0,0.8000 ) 100%);
    }

    &::after {
      content: '';
      display: inline-block;
      width: 200px;
      height: 2px;
      position: relative;
      top: -8px;
      margin-left: 40px;
      background: linear-gradient(90deg, rgba(222,25,0,0.8000) 0%, rgba(236,125,111,0) 100%);
    }
  }
  .company-content{   
    margin: 60px 120px 130px;
  }
}
.company-title-content{
  max-width: 600px;
  display: inline-block;

}
</style>
