<template>
  <div class="company-box company-intro">
    <div class="company-item">
      <div class="company-title">
        <!-- 企业简介 -->
        {{ $t('CompanyIntro') }}
      </div>
      <div class="company-content">
        <div class="company-img-box">
          <img class="company-img" src="@/assets/images/company-info.png" alt="">
        </div>
        <!-- text_hiddle_11 -->
        <div class="company-text ">
          {{ $t('BrandIntroduction') }}
          <br>
          <br>
          {{ $t('BrandIntroduction1') }}
        </div>
      </div>
    </div>

    <div class="company-item">
      <div class="company-title">
        <!-- 企业文化 -->
        {{ $t('CompanyCulture') }}
      </div>
      <div class="company-list">
        <div 
          v-for="(item, index) in companyList"
          :key="index"
          @click="skipCulture(index)"
          class="company-list-item">
          <div 
            class="company-list-name text_hiddle_1" 
            :title="item.name">{{ item.name }}</div>
          <div 
            class="company-list-content text_hiddle_4"
            :title="item.content">{{ item.content }}</div>
          <i class="icon-you iconfont"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 关于诺多康页面
export default {
  name: 'company-info',
  components: {
  },
  computed: {
    companyList () {
      return [{
        // name: '品牌文化',
        name: this.$t('BrandCulture'),
        content: this.$t('BrandCulture1Content'),
      }, {
        // name: '品牌精神',
        name: this.$t('BrandSpirit'),
        content: 
          this.$t('Collaboration') + '、'+
          this.$t('pragmatic') + '、' +
          this. $t('HighEfficiency') + '、'+
          this.$t('flexible') ,
      }, {
        // name: '品牌价值观',
        name: this.$t('BrandValues'),
        content: this.$t('BrandValues1'),
      }, {
        // name: '品牌哲学',
        name: this.$t('BrandPhilosophy'),
        content:  this.$t('BrandPhilosophy1'),
      }]
    }
  },
  methods: {
    // 跳转企业文化
    skipCulture (index) {
      this.$router.push({
        name: 'CompanyCulture',
        query: {
          curltureId: index,
          title: '企业文化'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.company-box{
  .company-item{
    margin-top: 60px;
  }
  .company-title {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    text-align: center;

    &::before {
      content: '';
      display: inline-block;
      width: 200px;
      height: 6px;
      margin-right: 40px;
      position: relative;
      top: -8px;
      background: url(@/assets/images/title-left.png);
    }

    &::after {
      content: '';
      display: inline-block;
      width: 200px;
      height: 6px;
      position: relative;
      top: -8px;
      margin-left: 40px;
      background-size: 100% 100%;
      background: url(@/assets/images/title-right.png);
    }
  }
  .company-content{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .company-img-box{
      width: 590px;
      min-height: 300px;
      background: #141211;
      position: relative;
    }
    .company-img {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      // width: 490px;
      background: #368fff;
    }
    .company-text{
      flex: 1;
      padding: 32px 120px 0px 20px;
      line-height: 24px;
      text-align: justify;
    }
  }
  .company-list{
    display: flex;
    justify-content: center;
    margin: 60px 0 120px;
  }
  .company-list-item {
    width: 176px;
    height: 148px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(139,144,160,0.2000); 
    padding: 20px;
    margin: 0 20px;
    cursor: pointer;
    position: relative;
    transition-duration: .3s;
    .company-list-name{
      font-size: 24px;
      line-height: 32px;
      color: #333; 
    }
    .company-list-content{
      margin-top: 20px;
      font-size: 14px;
      line-height: 24px;
      color: #666; 
    }
    .icon-you{
      position: absolute;
      top: 28px;
      right: 12px;
      color: #DE1900;
      opacity: 0;
    }
    &:hover {
      box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.0600);
      background: url(@/assets/images/hover_bg.png);
      background-size: 100% 100%;
      .icon-you{
        opacity: 1;
      }
    }
  }
}
</style>
