//1.导入Vue和VueRouter的包
import Vue from 'vue'
import VueRouter from 'vue-router'

// 页面地址
// import CommonPage from '@/view/common-page'
import index from '@/view/index'
import HomePage from '@/view/homePage/home-page'
import CompanyInfo from '@/view/companyInfo/company-info'
import CompanyIntro from '@/view/companyInfo/company-intro'
import CompanyCulture from '@/view/companyInfo/company-culture'

import ProductList from '@/view/productInfo/product-list'
import ProductDetail from '@/view/productInfo/product-detail'

import JournalismList from '@/view/journalismInfo/journalism-list'
import JournalismDetail from '@/view/journalismInfo/journalism-detail'

import CaseList from '@/view/caseInfo/case-list'
import CaseDetail from '@/view/caseInfo/case-detail'

import JoinUs from '@/view/joinUs/join-us'
import RelationUs from '@/view/relationUs/relation-us'


//2.调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(VueRouter)
 
const routes = [
    {
        path: '*',
        redirect: '/mage/index'//重定向
    },
    // 首页
    {
        path: '/mage/index',
        name: 'index',
        component: index,
    },
    // 首页
    {
        path: '/mage/HomePage',
        name: 'HomePage',
        component: HomePage,
    },

    // 关于诺多康
    {
        path: '/mage/CompanyInfo',
        name: 'CompanyInfo',
        component: CompanyInfo
    },
    // 企业简介
    {
        path: '/mage/CompanyIntro',
        name: 'CompanyIntro',
        component: CompanyIntro
    },
    // 企业文化
    {
        path: '/mage/CompanyCulture',
        name: 'CompanyCulture',
        component: CompanyCulture
    },

    // 产品解决方案列表
    {
        path: '/mage/ProductList',
        name: 'ProductList',
        component: ProductList
    },
    // 产品解决方案详情
    {
        path: '/mage/ProductDetail',
        name: 'ProductDetail',
        component: ProductDetail
    }, 

    // 新闻list
    {
        path: '/mage/JournalismList',
        name: 'JournalismList',
        component: JournalismList
    },
    // 新闻detail
    {
        path: '/mage/JournalismDetail',
        name: 'JournalismDetail',
        component: JournalismDetail
    }, 

    // 成功案例list
    {
        path: '/mage/CaseList',
        name: 'CaseList',
        component: CaseList
    },
    // 成功案例detail
    {
        path: '/mage/CaseDetail',
        name: 'CaseDetail',
        component: CaseDetail
    }, 

    // 加入我们
    {
        path: '/mage/JoinUs',
        name: 'JoinUs',
        component: JoinUs
    }, 

    // 联系我们
    {
        path: '/mage/RelationUs',
        name: 'RelationUs',
        component: RelationUs
    }, 

]


//3.创建路由的实例对象
const router = new VueRouter({
    mode: 'hash',
    routes
})

//4.向外共享路由的实例对象
export default router