<template>
  <div class="product_content">
    <div class="product_mainTitle">
      <!-- 成功案例 -->
      {{ $t('CaseList') }}
    </div>
    <div class="product_two">
      <div 
        v-for="(item, index) in itemShowList"
        :key="index"
        class="product_two_item"
        @click="skipEvent(item.name, index)">
        <div class="product_two_img">
          <img :src="item.url" alt="">
        </div>
        <div class="product_two_right">
          <div 
            class="product_two_name text_hiddle_1"
            :title="item.name">
            {{ item.name }}
          </div>
          <div 
            class="product_two_EleName"
            :title="item.EleName">
            {{ item.EleName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 案例列表
export default {
  name: 'case-list',


  data () {
    return {
      caseList: [],
      itemShowList: [] // 展示列表
    }
  },

  mounted () {
    // 添加对 sessionStorage 的监听
    this.caseList = JSON.parse(sessionStorage.getItem('caseList'))

    this.init()
    window.addEventListener("setItem", () => {
      this.init()
    });
  },

  methods: {
    init () {
      let language = sessionStorage.getItem("language")
      this.itemShowList = []
      if (language === 'zh') {
        this.caseList.forEach((item) => {

          let EleName = ''
          item.list.forEach((item1, index1) => {
            EleName += '\n' + (index1 + 1) + '.' + item1.caseDetailTitle
          })

          this.itemShowList.push({
            name: item.caseName,
            url: item.casePicUrl,
            EleName: '部分代表业绩如下：' + EleName
          })
        })
      } else {
        this.caseList.forEach((item) => {

          let EleName = ''
          item.list.forEach((item1, index1) => {
            EleName += '\n' + (index1 + 1) + '.' + item1.caseDetailTitleEng
          })

          this.itemShowList.push({
            name: item.caseNameEng,
            url: item.casePicUrl,
            EleName: 'Some representative achievements are as follows:' + EleName
          })
        })
      }
    },

    skipEvent (title, index) {
      this.$router.push({
        name: 'CaseDetail',
        query: {
          title: title,
          index: index
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel_img{
  height: 400px;
  width: 100%;
  background: #ccc;
}
.product_mainTitle{
  margin-top: 60px;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    width: 200px;
    height: 6px;
    margin-right: 40px;
    position: relative;
    top: -8px;
    background: url(@/assets/images/title-left.png);
  }

  &::after {
    content: '';
    display: inline-block;
    width: 200px;
    height: 6px;
    position: relative;
    top: -8px;
    margin-left: 40px;
    background-size: 100% 100%;
    background: url(@/assets/images/title-right.png);
  }
}

.product_two{
  background: #F9FAFC;
  padding: 0 120px;
  margin-top: 60px;
}

.product_two_item {
  width: 100%;
  height: 280px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid rgba(139,144,160,0.2000);
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: left;
  margin-bottom: 60px;
  transition-duration: .3s;
  .product_two_img {
    width: 400px;
    height: 280px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product_two_right {
    width: calc(100% - 400px);
    max-height: 200px;
    overflow-y: auto;
    min-width: 0;
    flex: 1;
    padding: 40px 40px 0;
  }
  .product_two_name{
    font-size: 24px;
    color: #333;
    line-height: 32px;
    font-weight: 500;
  }
  .product_two_EleName{
    font-size: 16px;
    color: #333;
    line-height: 24px;
    margin-top: 20px;
    white-space: pre-wrap;
  }
  .product_two_list{
    color: #333;
    margin-top: 40px;
    div{
      margin-bottom: 8px;
    }
  }
  &:hover {
    box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.0600);
  }
}
</style>
